import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from './_auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  landingPageFlag?:boolean;
  title = 'rusa-webui';
  routerChanged: boolean;
  timeout: any;

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private authService: AuthService) 
    {
    this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.routerChanged = true;
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.timeout = setTimeout(() => {
          clearTimeout(this.timeout);
          this.routerChanged = false;
        }, 1000);
      }
    });
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomLoginService } from '../../shared/services/custom-login.service';

@Component({
  selector: 'app-confirm-verifcation',
  templateUrl: './confirm-verifcation.component.html',
  styleUrl: './confirm-verifcation.component.scss'
})
export class ConfirmVerifcationComponent {
  accountActivated:boolean ;
  constructor(private route :ActivatedRoute, private customLoginService: CustomLoginService, public router: Router){
    
  }
  ngOnInit(): void {
    this.ConfirmUserActivation(); 
  }

  ConfirmUserActivation(){
    const code = this.route.snapshot.queryParams['code'];
    const data = {
      "emailToId": "",
      "emailToName": "",
      "userUid": code
    }
    this.customLoginService.ConfirmUserActivation(data).subscribe(
      (data) => {
        this.accountActivated = true; 
      },
      (error: any) => {
        this.accountActivated = false; 
      }
    );
  }
  signin(){
    this.router.navigateByUrl('/disclaimer');
  }
  

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsasLoadingService } from '../../shared/components/usas-loading/usas-loading.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomLoginService } from '../../shared/services/custom-login.service';
import { AuthService } from '../../_auth/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  userLogin: FormGroup;
  isLoading: boolean = false;
  
  constructor(private fb: UntypedFormBuilder,  private messageService: MessageService, private authService: AuthService, public router: Router, private customLoginService: CustomLoginService, private usasLoadingService: UsasLoadingService){
    this.usasLoadingService.hide();
    this.usasLoadingService.isLoading.next(false);
    if(sessionStorage.getItem('userSignin')){
      this.router.navigate(["/landing"]);
    }

  }

  ngOnInit(): void {
    this.userLogin = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.pattern(/^\s*([a-zA-Z][\w+-]+(?:\.\w+)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/)]),
      password: this.fb.control('', Validators.required)
    });
  }

  externalUserLogin() {
    if(this.userLogin.invalid) {
      this.userLogin.markAllAsTouched();
      return;
    } else {
      this.usasLoadingService.show();
      this.usasLoadingService.isLoading.next(true);
      let customSignInObj = {
        'userName': this.userLogin.controls[
          'email'
        ].value,
        'password': this.userLogin.controls[
          'password'
        ].value
      };
      this.customLoginService.getCustomLoginResp(customSignInObj).subscribe(
        (data) => {
          this.usasLoadingService.hide();
          this.usasLoadingService.isLoading.next(false);
          if (!data.errors.length) {
            sessionStorage.setItem('usasToken', data.accessToken);
            this.authService.isAuthenticated();
            sessionStorage.setItem('user-accepted', 'true');
            sessionStorage.setItem('userSignin', 'true');
            this.router.navigateByUrl('/landing');
          }
        },
        (error: any) => {
          this.usasLoadingService.hide();
          this.usasLoadingService.isLoading.next(false);
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Access Denied',
            detail: error,
          });
        }
      );
    }
  }

  createNewAccount() {
    this.router.navigateByUrl('/user-account');
  }

  login(){
    if(!sessionStorage.getItem('userSignin')){
      sessionStorage.setItem('userSignin', 'true');
      sessionStorage.removeItem('userSignout');
      this.router.navigate(["/landing"]);
    }else{
      sessionStorage.removeItem('userSignin');
    }
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }

  contactUs() {
    this.router.navigateByUrl('/contact-us');
  }

  faqsList() {
    this.router.navigateByUrl('/faq');
  }
}

import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-add-internal-user-page',
  templateUrl: './add-internal-user-page.component.html',
  styleUrl: './add-internal-user-page.component.scss'
})
export class AddInternalUserPageComponent {
  public userSearchResults: any
  public showSearchResults: boolean = false;
  constructor(private messageService: MessageService){}

  handleSearch(event:any){
    this.userSearchResults = event;
    if(event.message === 'Success'){
      this.showSearchResults = true;
    }else{
      this.showSearchResults = false;
    }
  }

}

<div class="devkit-ext-btmbar-wrapper">
	<div class="devkit-ext-btmbar">
	  <div class="btmbar-content">
		<div class="logo-addr-wrapper">
		  <img class="dot-logo" src="assets/images/DOT-logo.svg" alt="D O T Triskelion" width="63" height="64">
		  <div class="faa-addr">
			<p>U.S. Department of Transportation</p>
			<p>Federal Aviation Administration</p>
			<p>800 Independence Avenue, SW</p>
			<p>Washington, DC 20591</p>
			<p>(866) TELL-FAA | (866) 835-5322</p>
		  </div>
		</div>
		<div class="btmbar-col">
		  <ul>
			<li>
			  <a href="https://www.faa.gov/privacy">Privacy Policy</a>
			</li>
			<li>
			  <a href="https://www.faa.gov/accessibility">Accessibility</a>
			</li>
		  </ul>
		</div>
	  </div>
	</div>
  </div>
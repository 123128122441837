import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomLoginService } from '../../shared/services/custom-login.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup;
  resetForm: FormGroup;
  showSectionOne: boolean = true;
  showSectionTwo: boolean = false;
  showSectionThree: boolean = false;
  securityQuestions:any[];
  resetPasswordQuestions: any;
  isAnswersCorrect:boolean = true

  constructor(private fb: FormBuilder,  public router: Router, private customLoginService: CustomLoginService) {}

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z][\w+-]+(?:\.\w+)?@(?!.*\bfaa\.gov\b)[\w-]+(?:\.[a-zA-Z]{2,10})+$/i)]),
    });
  }

  submitSectionOne() {
    if(this.resetForm.invalid) {
      this.resetForm.markAllAsTouched();
      return;
    } else{
      this.isAnswersCorrect = true;
    const data = {
      email: this.resetForm.get('email')?.value
    }
    this.customLoginService.VerifyUser(data).subscribe(
      (data) => {
        if(data.status){
        this.resetPasswordQuestions = data;
        this.securityQuestions = data.securityQuestions;
        this.securityQuestions.forEach((questions) =>{
          this.resetForm.addControl(questions.questionId, this.fb.control(''))
        })
        
        this.showSectionOne = !this.showSectionOne; //false
        this.showSectionTwo = !this.showSectionTwo; //true
      }else{
        this.isAnswersCorrect = false;
      }
      },
      (error: any) => {
        this.isAnswersCorrect = false;
      }
    );
    } 
  }

  submitSectionTwo() {
    if(this.resetForm.invalid) {
      this.resetForm.markAllAsTouched();
      return;
    }else{
      this.isAnswersCorrect = true;
      this.securityQuestions.forEach((questions) =>{
        Object.keys(this.resetForm.controls).forEach((control: string) => {
          if(questions.questionId == control){
            questions.questionAnswer = this.resetForm.controls[control].value
          }
        })
      })
      const data = {
        message: this.resetPasswordQuestions.message,
        email: this.resetPasswordQuestions.email,
        securityQuestions: this.securityQuestions
      }
      this.customLoginService.VerifyAndGenerateLink(data).subscribe(
        (data) => {
          if(data.status){
            this.showSectionTwo = !this.showSectionTwo; //false
            this.showSectionThree = !this.showSectionThree; //true
          }else{
            this.isAnswersCorrect = false;
          }
        },
        (error: any) => {
          this.isAnswersCorrect = false;
        }
      );
    } 
  }

  signin() {
    this.router.navigateByUrl('/disclaimer');
  }
}

import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { UsasLoadingService } from './usas-loading.service';

@Component({
  selector: 'app-usas-loading',
  templateUrl: './usas-loading.component.html',
  styleUrl: './usas-loading.component.scss'
})
export class UsasLoadingComponent {
  @Input() isSection: boolean;
  isLoading: Subject<boolean> = this.usasLoadingService.isLoading;
  constructor(private usasLoadingService: UsasLoadingService){}
}








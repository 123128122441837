import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, UrlSerializer, UrlTree } from '@angular/router';
import { AppSettings } from '../app-config.module';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserDataService } from '../shared/services/user-data.service';
import { AuthorizationService } from './authorization.service';
import { UserDetailsService } from '../shared/services/user-details.service';
import { UsasLoadingComponent } from '../shared/components/usas-loading/usas-loading.component';
import { UsasLoadingService } from '../shared/components/usas-loading/usas-loading.service';
//import { stringify } from 'querystring';

const nameConstants = {
  midQueryParam: 'MID',
  applicantQueryParam: 'APPL_ID'
}
@Injectable()
export class AuthGuardService implements CanActivate {
  private _appSettings;
  private currentPath = '/';
  constructor(private authService: AuthService,
    public router: Router,
    private userDataService: UserDataService,
    private authorizationService: AuthorizationService,
    private userDetailsService: UserDetailsService,
    private serializer: UrlSerializer,
    private usasLoadingService: UsasLoadingService) {
    this._appSettings = AppSettings;
    this.currentPath = window.location.pathname;
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authResp = this.authService.isAuthenticated();

    if (typeof authResp === 'boolean') {
      let queryparams = (new URL(window.location.href)).searchParams;
      let params = {};
      let loginUrl = this._appSettings.Login;
      if(queryparams && queryparams.get(nameConstants.applicantQueryParam)){
        params = {APPL_ID: queryparams.get(nameConstants.applicantQueryParam)};
        //sessionStorage.setItem('relayState', '?'+stringify(params));
      }
       if (!authResp) {
           let params = next.queryParams;
           if(!sessionStorage.getItem('userSignin')){
            this.router.navigateByUrl('/signin');
           }else if(sessionStorage.getItem('unAuthorized')){
            this.router.navigate(["/access-denied"]);
           }else{
            if(params && Object.keys(params).length > 0) {
              const tree = this.router.createUrlTree(['/'], { queryParams: params});
              loginUrl = `${loginUrl}${this.serializer.serialize(tree)}`;
            }
            window.location.href = `${loginUrl}`;  
           }  
           return false;
          
       } else {
        this.userDataService.setTokenAvailability(true);
        return true;
       }
    }
    this.usasLoadingService.isLoading.next(true);
    return true;
  }
}

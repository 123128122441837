import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from './../../app-config.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { AuthService } from '../../_auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  _appSettings;

  constructor( private httpClient: HttpClient, private logger: LoggerService, 
    private authService: AuthService) {
    this._appSettings = AppSettings;
   }

   public getStateDetails(): Observable<any> {
    return this.httpClient.get<any>(`${AppSettings.API_ENDPOINT}lookup/getallStates`)
               .pipe(catchError(err => this.errorHandler(err)));
  }

  errorHandler(error: any): any {
    this.logger.error(error);
    return throwError(new Error(error));
  }
}

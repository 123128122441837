import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'faa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() footerLandingFlag?:boolean; 

  ngOnInit(): void {
  }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../_auth/auth.service';
import { UserDataService } from '../../shared/services/user-data.service';
import { TitleBlinker } from '../../shared/services/tab-blink.service'
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { UserDetailsService } from '../../shared/services/user-details.service';

@Component({
  selector: 'app-user-idle-inactive-timeout',
  templateUrl: './user-idle-inactive-timeout.component.html',
  styleUrls: ['./user-idle-inactive-timeout.component.scss']
})
export class UserIdleInactiveTimeoutComponent implements OnInit {
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime:number;
  timeoutWarning:number;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  display: boolean = true;
  sessionExpire = 0;
  tokenAvailabilitySubscribe: any;


  // tslint:disable-next-line:max-line-length
  constructor (
    private authService: AuthService,
    private userDataService: UserDataService,
    private userDetailsService: UserDetailsService,
    private titleBlinker: TitleBlinker,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.tokenAvailabilitySubscribe = this.userDataService.getTokenAvailability().pipe().subscribe(tokenAvailable => {
      if (tokenAvailable) {
        this.setTimer();
      }
    });
  }

  initialLoad() {
    this.resetTimer();
    this.setSessionStorageValue('sessionExpire', (moment().unix() + this.endTime * 60 * 1000).toString());
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.tokenAvailabilitySubscribe = this.userDataService.getTokenAvailability().pipe().subscribe(tokenAvailable => {
        if (tokenAvailable) {
          this.setSessionStorageValue('sessionExpire', (moment().unix() + this.endTime * 60 * 1000).toString());
        }
      });
      this.resetTimer();
    });
  }

  setTimer() {
    this.endTime = this.authService.getUserData().idleTimeout;
    this.timeoutWarning =this.authService.getUserData().warningTime;
    this.initialLoad();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setSessionStorageValue(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getSessionStorageValue(key: string): string {
    return sessionStorage.getItem(key)!;
  }

  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;
    this.sessionExpire = 0;
    this.secondsDisplay = 0;
    this.minutesDisplay = 0;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this._ngZone.runOutsideAngular(() => {
      this.timerSubscription = timer(0, interval).pipe(
        take(duration)
      ).subscribe(value => {
        const sessionExpireL = parseInt(this.getSessionStorageValue('sessionExpire'), 10);
        const calDiffTime = sessionExpireL - moment().unix() - (+value * interval);
        if (calDiffTime && calDiffTime < 6000000) {
          this._ngZone.run(() => { this.render(calDiffTime); });
        } else {
          this.render(calDiffTime);
        }
        if (this.sessionExpire && this.sessionExpire > 0 && this.sessionExpire !== sessionExpireL) {
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          this._ngZone.run(() => { setTimeout(() => {this.resetTimer(); }, 300); });
        } else {
          this.sessionExpire = sessionExpireL;
        }
      },
      err => { },
      () => {
        this.logoutUser();
      });
    });
  }

  private render(count: number) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  logoutUser(event?:any) {
    sessionStorage.removeItem('sessionExpire');
    window.dispatchEvent(new CustomEvent("logoutUser"));
  }

  showTabBlink() {
    this.titleBlinker.blink('Session Timeout Warning');
  }

  extendTime() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.resetTimer();    
  }
}

export class AppSettings {
  public static get API_ENDPOINT(): string {
    return '/api/';
  }

  public static get Base_URL(): string {
    return '/';
  }

  public static get Login(): string {
    return '/api/auth/login';
  }

  public static get logout(): string {
    return '/api/auth/logout';
  }

  public static get Custom_Login(): string {
    return '/auth/LoginWithCredentials';
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';
//import { DiwsCommonService } from 'app/shared/services/diws-common.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-security-warning',
  templateUrl: './security-warning.component.html',
  styleUrls: ['./security-warning.component.scss']
})
export class SecurityWarningComponent implements OnInit {

  constructor(private router: Router,
    //private diwsCommonService: DiwsCommonService,
    private serializer: UrlSerializer,
    private route: ActivatedRoute) { }
  params: Params | undefined;
  ngOnInit(): void {
     this.params = this.route.snapshot.queryParams   
     if(sessionStorage.getItem('usasToken')){
      this.router.navigateByUrl('/landing');
     }  
  }

  public accessDIWS() {
    sessionStorage.setItem('user-accepted', 'true');
    this.router.navigateByUrl('/signin');
    /*this.diwsCommonService.getUserSignInSelection().subscribe((value: any) => {
      if (!value) {
        let url = '/'
        if(this.params && Object.keys(this.params).length > 0) {
          const tree = this.router.createUrlTree([url], { queryParams: this.params});
          url = this.serializer.serialize(tree);
        }
        this.router.navigateByUrl(url);
      } else {
        this.router.navigateByUrl('/signin');
      }
    });*/
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from './../../app-config.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { AuthService } from '../../_auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomLoginService {

  _appSettings;

  constructor( private httpClient: HttpClient, private logger: LoggerService) {
    this._appSettings = AppSettings;
   }
   

   public getCustomLoginResp(customSignInObj: any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/Login`,
        customSignInObj
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public userRegistration(userObj: any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/UserRegistration`,
        userObj
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public resetPassword(userObj: any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/ResetPassword`,
        userObj
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public getAllSecurityQuestions(): Observable<any> {
    return this.httpClient
      .get<any>(
        `${this._appSettings.API_ENDPOINT}Lookup/GetAllSecurityQuestions`
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public VerifyUser(data:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/VerifyUser`, data
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }
   
   public VerifyAndGenerateLink(data:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/VerifyAndGenerateLink`, data
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public ConfirmUserActivation(data:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/ConfirmUserActivation`, data
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }

   public VerifyResetPasswordLink(data:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/VerifyResetPasswordLink`, data
        )
      .pipe(catchError((err) => this.errorHandler(err)));
   }


  errorHandler(error: any): any {
    this.logger.error(error);
    return throwError(new Error(error.error.errors));
  }
}

<main id="main-content" class="grid nested-grid ">
    <div class="col-12 mt-4 px-6">
        <h1>Search Airman / Operator</h1>
        <div class="p-5 bg-cyan-50 border-300">
            <app-searchpanel (searchResults)="handleSearch($event)" searchType="Airman/Operator"></app-searchpanel>
        </div>
        <div *ngIf="searchResults">
            <app-search-results [searchResults]="searchResults"></app-search-results>
        </div>
    </div>
</main>

<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid ">
    <div class="col-10 xl:col-10 mt-4 px-6">
        <h1>Add New User</h1>
        <div class="p-5 bg-cyan-50 border-300">
            <app-add-internal-user-search (newUserSearchResults)="handleSearch($event)"></app-add-internal-user-search>
        </div>
        <div *ngIf="showSearchResults">
            <app-add-internal-user-search-list [userSearchResults]="userSearchResults"></app-add-internal-user-search-list>
        </div>
    </div>
</main>
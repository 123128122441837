<form [formGroup]="searchForm">
    <div class="grid">
        <div class="form-grid mt-2">
            <div class="grid pl-2">               
                <p class="mt-0">Enter the full FAA email address of the employee and click verify to assign a role in USAS</p>
            </div>
            <div class="form-group field col-6 xl:col-6 md:col-6 sm:col-12 pl-0">
                <label for="email">Email<span class="text-red-700">*</span></label>
                <input pInputText type="email" id="email" formControlName="email" (keyup)="clearErrorChange()" (keydown)="clearErrorChange()" (change)="clearErrorChange()" class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" type="email" maxLength="45" required/>
                <div *ngIf="searchForm?.get('email') && searchForm?.get('email')?.errors && searchForm?.get('email')?.invalid && searchForm?.get('email')?.errors?.required && searchForm?.get('email')?.touched && !userSearchResult" class="text-sm text-red-700">Email is required.</div>
                <div id="userSearchResponse" *ngIf="userSearchResult" class="text-sm text-red-700">{{userSearchResult}}</div>
                <div class="text-sm text-red-700" 
                    *ngIf="searchForm?.get('email') && searchForm?.get('email')?.errors && searchForm?.get('email')?.touched 
                    && !(searchForm?.get('email')?.errors?.required) ">
                    Please enter a valid FAA email address.
                </div>
            </div>
        </div>  
    </div>
    
    <div class="grid">
        <button type="submit"  class="primary default" (click)="handelSearch()">Verify</button>
        <button class="secondary default" (click)="cancelSearch()">Cancel</button>
    </div>
</form>
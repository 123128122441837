import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDataService } from '../shared/services/user-data.service';
import { Observable, Subject } from 'rxjs';
import { Params, Router, UrlSerializer } from '@angular/router';
import { isNumber } from 'lodash-es';


const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthService {

  private _userService;
  _userActionOccured: Subject<void> = new Subject();

  tokenAvailabilitySubscribe = null;

  constructor(
    private cookieService: CookieService,
    private userService: UserDataService,
    private router: Router,
    private serializer: UrlSerializer
    ) {
    this._userService = userService;
  }

  public isAuthenticated(): boolean {
    let jwt;
    if (!sessionStorage.getItem('usasToken')) {
      const cookie = this.getCookie('usasauthtoken');
      if (cookie) {
        const parsedToken = JSON.parse(cookie);
        const isSuccess = parsedToken['success'];
        jwt = parsedToken['accessToken'];
        let errors = parsedToken['errors'];
        if (isSuccess && jwt && !errors.length) {
          sessionStorage.setItem('usasToken', jwt);
          this.deleteAllCookies();
        } else {
          let error = this.getErrorMessage();
          sessionStorage.removeItem('usasToken');
          sessionStorage.setItem('autherror', error);
          this.router.navigate(["/access-denied"]);
          return false;
        }
      }
    } else {
      jwt = sessionStorage.getItem('usasToken');
    }
    if (jwt) {
      if (jwt !=='null') {
        let activeId = this.getActiveId();
        if (activeId !== '' && sessionStorage.getItem('currentUser') && sessionStorage.getItem('currentUser') !== null) {
          if (activeId === sessionStorage.getItem('currentUser') && this.isValidJWT()) {
            if(this.isExternalUser() || this.isInternalUser() || this.isInternalAdminUser()){
              return true;
            }else{
              sessionStorage.removeItem('usasToken');
              sessionStorage.setItem('unAuthorized', 'true');
              return false;
            }
            
          } else {
            this.clearUserSessionData();
            return false;
          }
        } else if (activeId !== '' && !sessionStorage.getItem('currentUser') && this.isValidJWT()) {
          
            if(this.isExternalUser() || this.isInternalUser() || this.isInternalAdminUser()){
              sessionStorage.setItem('user-accepted', 'true');
              sessionStorage.setItem('currentUser', activeId);
              sessionStorage.setItem('usasToken', jwt);
              this.userService.setUser({username: activeId.sub});
              return true;
            }else{
              sessionStorage.removeItem('usasToken');
              sessionStorage.setItem('unAuthorized', 'true');
              return false;
            }
        } else {
          return false;
        }
      } else if (jwt === 'null') {
        let error = this.getErrorMessage();
        sessionStorage.removeItem('usasToken');
        sessionStorage.setItem('autherror', error);
        this.router.navigate(['/access-denied']);
        return false;
      }
    }
    return false;
  }

  getHeadersParams() {
    const authToken = this.getJWT();
    let headers: any;
    if (authToken) {
        headers = {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Content-Type' : 'application/json',
          'Authorization': `Bearer ${authToken}`};
    }
    return headers;
  }

  convertEmailToUserName(email: string) {
    email = decodeURIComponent(email);
    let userName   = email.substring(0, email.lastIndexOf('@'));
    if (userName === '') {
      userName =  email;
    }
    userName = userName.replace(/\./g, ' ');
    return userName;
  }

  isValidJWT(jwt: string = this.getJWT()): boolean {
    return !jwtHelper.isTokenExpired(jwt);
  }

  getJWT(): string {
    let jwt;
    if (sessionStorage.getItem('usasToken')) {
      jwt = sessionStorage.getItem('usasToken');
    } else {
      const usasCookie = this.cookieService.get('usasauthtoken');
      if (usasCookie) {
        const token = JSON.parse(usasCookie);
        sessionStorage.setItem('usasToken', token['accessToken']);
        jwt = token['accessToken'];
      }
    }
    return jwt;
  }

  getErrorMessage() {
    const usasCookie = this.cookieService.get('usasauthtoken');
    if (usasCookie) {
      const jwt = JSON.parse(usasCookie);
      if (jwt["errors"] && jwt["errors"].length) {
        return jwt["errors"][0];
      }
    }
  }

  // TODO will be replace with JWT Code
  getCookie(cname: string) {
    const name: string = cname + '=';
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        let newName = this.removeSpecialCharacters(name);
        document.cookie = newName + "=;expires=Thu, 01 Jan 1900 00:00:00 GMT";
    }
    return '';
  }

  removeSpecialCharacters(input: string){
    let lower = input.toLowerCase();
    let upper = input.toUpperCase();
    let result = "";
    for (let i=0;i<lower.length;i++)
    {
      if (isNumber(input[i]) || (lower[i] != upper[i]) || (lower[i] === ''))
      {
        result += input[i];
      }
      return result;
    }
    return '';
  }

  clearUserSessionData() {
    this.deleteAllCookies();
    sessionStorage.clear();
  }

  getActiveId() {
    const jwt = this.getJWT();
    if (jwt) {
      if (jwt !== 'null') {
        const user = jwtHelper.decodeToken(jwt);
        return user.firstName;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getUserData(){
    const jwt = this.getJWT();
    const user = jwtHelper.decodeToken(jwt);
    return user;
  }

  getJWTExp() {
    const jwt = this.getJWT();
    const token = jwtHelper.decodeToken(jwt);
    let current_time = Date.now() / 1000;
    const date = new Date();
    const exp = date.setUTCMinutes(token.exp);
    return exp;
  }

  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  logout() {
    // remove user data from local/session storage when user is logged out
    this.clearUserSessionData();
  }

  isExternalUser(){
    return this.getUserData().userRole ? this.getUserData().userRole === 'EXTERNAL_USER' : false
  }
  isInternalUser(){
    return this.getUserData().userRole ? this.getUserData().userRole === 'INTERNAL_USER' : false
  }
  isInternalAdminUser(){
    return this.getUserData().userRole ? this.getUserData().userRole === 'INTERNAL_ADMIN' : false
  }
  
}

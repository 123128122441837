<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid ">
    <div class="col-3 md:col-3">
        <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper" [formGroup]="userLogin">
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">External Users</div>
                <p class="text-sm">Please enter email and password to sign in</p>
                <div>
                    <label for="email1" class="block text-900 font-medium mb-1 mt-1">Email</label>
                    <input pInputText formControlName="email" (keyup.enter)="externalUserLogin()" id="email1" type="email" placeholder="Email address" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1">
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Email is required.</div>
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && !userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Please enter a valid email address.</div>
            
                    <label for="password1" class="block text-900 font-medium mb-1 mt-2">Password</label>
                    <!-- <input pInputText formControlName="password" (keyup.enter)="externalUserLogin()" id="password1" type="password" placeholder="Password" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1"> -->
                    <p-password id="password1" formControlName="password" (keyup.enter)="externalUserLogin()" [feedback]="false" [toggleMask]="true" class="block border-noround" placeholder="Password"/>
                    <div *ngIf="userLogin.controls.password.invalid && userLogin.controls.password.touched"
                        class="alert danger-alert error-font">Password is required.</div>
                </div>
                <button (click)="externalUserLogin()" class="primary default" pButton pRipple type="button" label="Sign in" class="w-full primary default mt-4"></button>
                <div class="flex align-items-center justify-content-start mb-2">
                    <a class="font-medium no-underline link-button cursor-pointer ml-0" (click)="forgotPassword()">Forgot password?</a>
                </div>
                <button pButton pRipple type="button" (click)="createNewAccount()" label="Request New User Account" class="w-full secondary default mt-2"></button>
            </ul>
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">FAA Users</div>
                <button pButton pRipple type="button" label="FAA User Sign in" class="w-full primary default mt-3" (click)="login()"></button>
            </ul>
        </div>
    </div>
    <div class="col-9 md:col-9">
        <div class="grid">
            <div class="col-8">
                <h1>Welcome to USAS</h1>
                <hr class="border-top-3 border-yellow-600">
                <p class="pr-3">
                    Please log in to the USAS application to provide a U.S. Agent for Service. You are ONLY required to provide a U.S. Agent for Service if you do not have a U.S. physical address of record. Click here (link) to go to Airmen Services (is this the correct name?) to review or modify your address of record. Individuals who hold or apply for certificates issued under 14 CFR parts 47, 61, 63, 65, 67, or 107 and who DO NOT have a U.S. physical address of record on file with the FAA must designate a U.S. Agent for Service and comply with LAW NUMBER starting on DATE. The U.S. agent would receive service of FAA documents on behalf of the applicant or certificate holder.
                </p>
                <a type="button" class="link-button"
                    href="#devkit-skip-to-main" aria-label="Policy Link"
                    title="Policy Link" target="_blank">Please click the link to view the policy</a>
            </div>
            <div class="col-3 md:col-3">
                <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper">
                    <ul class="devkit-left-nav-links p-3" id="devkit-left-nav-links-resources">
                        <div class="devkit-nav-heading">Resource Links</div>
                        <li><a class="devkit-left-nav-link"
                            href="#" aria-label="Link to User Guide"
                            title="User Guide" target="_blank">User Guide</a></li>
                        <li><a class="devkit-left-nav-link"
                            href="#" aria-label="Link to Policy"
                            title="Policy" target="_blank">Policy</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="faqsList()" aria-label="Link to FAQs"
                            title="FAQs">FAQ</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="contactUs()" aria-label="Link to Policy"
                            title="Policy">Contact Us</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0" aria-label="Link to Help" title="Help" (click)="contactUs()">Help</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid mt-3 mb-3 text-sm">
            <div class="col-8">
                <div class="devkit-info-message-wrapper" role="dialog" aria-label="info-message" aria-describedby="info-message-body">
                    <div class="devkit-message-body-wrapper">
                      <p class="devkit-message-body" id="info-message-body">
                        <b>Note:</b> Due to security requirements, after 60 minutes of inactivity you will 
                            be automatically logged off the application and required to login again before resuming activity
                      </p>
                    </div>
                  </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-8">
                <p-accordion class="text-sm">
                    <p-accordionTab header="Privacy Act Statement (5 U.S.C. 552a(e)(3))">
                        <dl>
                            <dt><b>Authority</b></dt>
                            <dd>Information on Federal Aviation Administration (FAA) USAS is solicited under the 
                                authority <a type="button" class="link-button"
                                href="https://www.ecfr.gov/current/title-14/chapter-I/subchapter-K/part-183" aria-label="Verify Regulation" target="_blank" title="Verify Regulation">VERIFY THIS LINK Title 14 Code of Federal Regulations Part 183.</a></dd>
                            <dt><b>Purpose</b></dt>
                            <dd>The purpose of Designee Management System is to standardize the management lifecycle 
                                process and oversight of designees who are representatives of the FAA authorized to perform 
                                certain certification related tasks. The purpose of collecting this information is also to 
                                allow individuals access to the system</dd>
                            <dt><b>Routine Uses</b></dt>
                            <dd>The information collected will be included in the system of records notice 
                                <a type="button" class="link-button"
                                href="https://www.govinfo.gov/content/pkg/FR-2002-05-07/pdf/02-10943.pdf" aria-label="DOT Records"
                                title="DOT Records" target="_blank">DOT/ALL 13- Internet/Intranet Activity and Access Records</a>
                                 and will be subject to the 
                                published routine uses including
                                <ul>
                                    <li>
                                        To provide information to any person(s) authorized to assist in an approved investigation 
                                        of improper access or usage DOT computer systems;
                                    </li>
                                    <li>
                                        To an actual or potential party or his or her authorized representative for the purpose 
                                        of negotiation or discussion of such matters as settlement of the case or matter, or 
                                        informal discovery proceedings;
                                    </li>
                                    <li>
                                        To contractors, grantees, experts, consultants, detailees, and other non-DOT employees 
                                        performing or working on a contract, service, grant, cooperative agreement, or other 
                                        assignment from the Federal government, when necessary to accomplish an agency function 
                                        related to this system of records; and
                                    </li>
                                    <li>
                                        To other government agencies where required by law.
            
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <p>
                            The Department has also published 15 additional routine uses applicable to all 
                            DOT Privacy Act systems of records. These routine uses are published in the Federal 
                            Register at 84 FR 55222 -  October15, 2019 and 77 FR 4279 – July 20, 2012, and under 
                            “Prefatory Statement of General Routine Uses” (available at link <a type="button" class="link-button"
                            href="https://www.transportation.gov/privacy/privacyactnotices" aria-label="Privacy Act Notices"
                            title="Privacy Act Notices" target="_blank">https://www.transportation.gov/privacy/privacyactnotices</a>).
                        </p>
                        <p>
                            <b>Disclosure:</b> Submission of the information is voluntary; however failure to submit required 
                            information will result in FAA’s denial of your access to the system
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Information Paperwork Reduction Act Statement">
                        <p>
                            The information is necessary to determine your eligibility to become and FAA designee. 
                            It is estimated that it will take approximately one minute per response. The information 
                            will become part of the Privacy Act system of records, DOT / FAA 830 Representative of the 
                            Administrator, and it will be used to evaluate your qualifications for appointment as a designee. 
                            It should be noted that a person is not required to collect information unless it displays a 
                            currently valid OMB control number. The OMB control number associated with this collection of 
                            information is 2120-0033. Comments concerning the accuracy of this burden and suggestions for 
                            reducing the burden should be directed to the FAA at: 800 Independence Ave SW, Washington, DC 20591,
                            Attn: Information Collection Clearance Officer, ABA-20
                        </p>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>

<main id="main-content" class="grid nested-grid ">
    <div class="col-12 mt-4 px-6">
        <h1>Manage Users</h1>
        <div class="p-5 bg-cyan-50 border-300">
            <app-searchpanel (searchResults)="handleSearch($event)" searchType="InternalUser"></app-searchpanel>
        </div>
        <div>
            <app-internal-user-list [searchResults]="searchResults"></app-internal-user-list>
        </div>
    </div>
</main>

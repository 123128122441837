import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../../_auth/auth.service';
import { AppSettings } from '../../../app-config.module';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  _appSettings;
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._appSettings = AppSettings;
  }

  // authToken = this.authService.getJWT();

  //setToken = this.logger.setCustomHttpHeaders(new HttpHeaders({'Authorization': `Bearer ${this.authToken}`}));

  public log(message:any) : void {
    //this.logger.log(message);
   // this.setToken;

  }

  public info(message:any) : void {
    //this.logger.info(message);
    //this.setToken;
  }

  public debug(message:any) : void {
    //this.logger.debug(message);
    //this.setToken;
  }

  public error(message:any) : void {
    //this.logger.error(message);
    //this.setToken;
  }

  public appAccessed(appCode:any): Observable<any | null | undefined> | null | undefined{
    const appAccessedString = sessionStorage.getItem('appAccessed');
    let appAccessedArray: Array<string> = [];
    try {
      appAccessedArray = appAccessedString ? JSON.parse(appAccessedString) : [];
    } catch (error) {
      appAccessedArray = [];
    } 
      
    if (appAccessedArray.includes(appCode)) {
      return;
    } else {
      appAccessedArray.push(appCode);
      sessionStorage.setItem('appAccessed', JSON.stringify(appAccessedArray));
      return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}Logs/AppAccessed`, {'AppCode': appCode})
      .pipe(catchError((err) => this.errorHandler(err)));
    }
  
  }

  errorHandler(error: any): any {
    if (error.status === 401) {
      window.location.href = 'signout';
    }
    //this.logger.error(error);
    return throwError(new Error(error.status + ': ' + error.statusText));
  }
}

<div class="grid w-auto">
    <div class="col-4 md:col-4 lg:col-4 sm:col-12 w-auto">
        <label for="userfirstname" class="font-semibold">First Name</label>
        <p id="userfirstname">{{selectedUser.firstName}}</p>
    </div>
    <div class="col-4 md:col-4 lg:col-4 sm:col-12 w-auto">
        <label for="userlastname" class="font-semibold">Last Name</label>
        <p id="userlastname">{{selectedUser.lastName}}</p>
    </div>
</div>
<div class="grid">
    <div class="col">
        <label for="useremail" class="font-semibold">Email</label>
        <p id="useremail">{{selectedUser.email}}</p>
    </div>
</div>
<form [formGroup]="internalUserForm" class="mt-2">
    <div class="grid">
        <div class="form-grid grid">
            <div class="field col-12 md:col-6 lg:col-6 md:ml-2">
                <label for="userrole" class="font-semibold">Select Role<span class="asterik">*</span></label>
                <p-dropdown id="userrole" formControlName="selectedRole" [options]="userRoles" optionLabel="roleName" optionValue="roleId"
                appendTo="body" autoWidth="false" [style]="{'width':'100%'}" [required]="true" (onChange)="onChange($event)"></p-dropdown>
                <div *ngIf="internalUserForm?.get('selectedRole')?.invalid && internalUserForm?.get('selectedRole')?.touched"
                        class="alert danger-alert error-font">Role is required. </div>
            </div>
            <div class="field col-12 md:col-6 lg:col-5 sm:ml-2">
                <label for="userstatus" class="font-semibold">Select Status<span class="asterik">*</span></label>
                <p-dropdown id="userstatus" formControlName="selectedStatus" [options]="userStatus" optionLabel="status" optionValue ="status"
                appendTo="body" autoWidth="false" [style]="{'width':'100%'}" [required]="true" (onChange)="onChange($event)"></p-dropdown>
                <div *ngIf="internalUserForm?.get('selectedStatus')?.invalid && internalUserForm?.get('selectedStatus')?.touched"
                        class="alert danger-alert error-font">Status is required. </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col">
            <button type="submit" [disabled]="disableBtn" [ngClass]="disableBtn ? 'disabled default': 'primary default'" (click)="addUser($event)">Save</button>
            <button class="secondary default" (click)="closeAgentModal(true, $event)">Cancel</button>
        </div>
    </div>
</form>


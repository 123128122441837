import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_auth/auth.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.scss'
})
export class FaqListComponent implements OnInit {

  userData:any;

  constructor(public router: Router, private authService: AuthService){}
  ngOnInit(): void {
    this.userData = this.authService.getUserData()
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }

  signin() {
    this.router.navigateByUrl('/signin');
  }
}

export class User {
  constructor(
    activeId: string,
    firstName?: string,
    lastName?: string) {
  }
}

// export class UserRecord {
//   userId: number;
//   userName: string;
//   firstName: string;
//   middleName: string;
//   lastName: string;
//   title: string;
//   dob: string;
//   ameSerialNo: string;
//   domainName: string;
//   domainUserName: string;
//   isLocked: boolean;
//   isDeactivated: boolean;
//   applications: string;
//   regionCode: string;
//   fullName: string;
//   cpRmoCodeId: number;
//   routingCode: string;
//   dutyTitle: string;
//   suSupervisorId: number;
//   suTrainerId: number;
//   isNewUser: boolean;
//   signatures: any;
// }

// export enum UserUISteps {
//   DemographicsInfo,
//   AMCSInfo,
//   ChapsInfo,
//   CPDSSInfo,
//   DiwsInfo,
//   Confirmation
// }

// export class typePreferences {
//   name: string;
//   value: string;
//   moduleCode: string;
// }

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddAgentModalComponent } from '../add-agent-modal/add-agent-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsasLoadingService } from '../../shared/components/usas-loading/usas-loading.service';
import { AuthService } from "../../_auth/auth.service";
import { AgentDetailsService } from '../../shared/services/save-agent-details.service';
import { AgentData } from '../../shared/models/agent-data-model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  providers: [DynamicDialogRef, DynamicDialogConfig]
})

export class LandingPageComponent implements OnInit {
  showAddAgentModal = false;
  showEditMode = false;
  addagentform: any;
  isLoading: boolean = false;
  userName = 'Guest';
  agentDetails: any;
  userData: any;
  ftnSearch: UntypedFormGroup | any;
  count: number = 0;
  isError: boolean = false;
  maxdobDate: Date;
  errorMsg: string;
  ftn: string;
  dob: string;
  applicantId:number;

  constructor(private fb: UntypedFormBuilder, private dialogService: DialogService, private usasLoadingService: UsasLoadingService,
    public agentDialogRef: DynamicDialogRef, private config: DynamicDialogConfig, private authService: AuthService, private agentDetailsService: AgentDetailsService,
    private messageService: MessageService, public router: Router,) {
    this.usasLoadingService.isLoading.next(false);
    window.dispatchEvent(new CustomEvent("updateHeader"));
    this.maxdobDate = new Date(); // Set maxDate to today
  }
  ngOnInit(): void {
    this.ftnSearch = this.fb.group({
      ftnNumber: this.fb.control('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]{8}$")]),
      dob: this.fb.control('', Validators.required),
    })
    const userData = this.authService.getUserData();
    if ((this.authService.isInternalUser() || this.authService.isInternalAdminUser()) && userData.userIsActive === 'Y') {
      this.router.navigate(["/search"]);
    } else if (userData.userIsActive === 'N') {
      this.router.navigate(['/access-denied']);
    }
    this.userData = this.authService.getUserData()
    const validToken = this.authService.isValidJWT();
    if (validToken) {
      this.userName = this.userData.firstName + ' ' + this.userData.lastName;
      if(sessionStorage.getItem('showEditMode')){
        this.showEditMode = true;
        this.handleFtnSubmit(sessionStorage.getItem('ftn'), sessionStorage.getItem('dob'));
      }
    }
  }

  addAgent() {
    if (this.showEditMode === true) {
      this.agentDialogRef = this.dialogService.open(AddAgentModalComponent, {
        header: 'New U.S. Agent',
        width: '58%',
        contentStyle: { 'padding': '0 25px 0 75px', 'font-size': '12px' },
        styleClass: 'agentDialogStyles',
        data: this.agentDetails
      });
    } else {
      this.agentDialogRef = this.dialogService.open(AddAgentModalComponent, {
        header: 'New U.S. Agent',
        width: '61%',
        contentStyle: { 'padding': '0 25px 0 75px', 'font-size': '12px' },
        styleClass: 'agentDialogStyles',
      });
    }

    this.agentDialogRef.onClose.subscribe((formData) => {
      const agentFormData = formData.formData;
      if (agentFormData && agentFormData.status !== "INVALID" && !formData.isCancel) {
        const reqData: AgentData = {
          isNewAgent: this.showEditMode ? "N" : 'Y',
          userId: this.userData.userId,
          applicantId : this.applicantId,
          agentId: 0,
          agentCompanyName: agentFormData.value.agentCompanyName?.trim(),
          firstName: !agentFormData.value.isAgentIndividual ? agentFormData.value.firstName?.trim() : agentFormData.value.pocfirstName?.trim(),
          lastName: !agentFormData.value.isAgentIndividual ? agentFormData.value.lastName?.trim() : agentFormData.value.poclastName?.trim(),
          suffix: !agentFormData.value.isAgentIndividual ? agentFormData.value.suffix?.trim() : agentFormData.value.pocsuffix?.trim(),
          middleName: !agentFormData.value.isAgentIndividual ? agentFormData.value.middleName?.trim() : agentFormData.value.pocmiddleName?.trim(),
          isAgentIndividual: !agentFormData.value.isAgentIndividual ? "Y" : "N",
          isAgentMilitaryEmp: agentFormData.value.isAgentMilitaryEmp ? 'Y' : 'N',
          addressLine1: agentFormData.value.addressLine1?.trim(),
          addressline2: agentFormData.value.addressline2?.trim(),
          city: agentFormData.value.city?.trim(),
          stateCd: agentFormData.value.state,
          zipCode: Number(agentFormData.value.zipCode),
          zip4Code: agentFormData.value.zip4Code ? Number(agentFormData.value.zip4Code) : null,
          email: agentFormData.value.email?.trim(),
          phone: agentFormData.value.phone ? agentFormData.value.phone : "",
          fax: agentFormData.value.fax ? agentFormData.value.fax : "",
          militaryUnitNo: 0,
          militaryBoxNo: 0,
          lastUpdateBy: 1,
          pocEmail: agentFormData.value.isAgentIndividual ? agentFormData.value.pocEmail?.trim() : "",
          pocFax: agentFormData.value.isAgentIndividual ? agentFormData.value.pocFax?.trim() : "",
          pocPhone: agentFormData.value.isAgentIndividual ? agentFormData.value.pocPhone?.trim() : ""
        }
        this.agentDetailsService.saveAgentDetails(reqData).subscribe((data) => {
          if (data) {
            this.showEditMode = true;  
            sessionStorage.setItem('showEditMode', "true"); 
            this.handleFtnSubmit(sessionStorage.getItem('ftn'), sessionStorage.getItem('dob'));
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'U.S. Agent saved successfully',
            });

          }
        });
      }
    });
  }

  handleFtnSubmit(ftn?:string | null, dob?:string | null) {
    if (this.ftnSearch.valid || sessionStorage.getItem('showEditMode')) {
      this.ftn = ftn ? ftn :this.ftnSearch.get('ftnNumber').value;
      const string = dob ? dob : this.ftnSearch.get('dob').value;
      this.dob = new Date(string).toISOString().split('T')[0];
      console.log(this.dob);
      const reqData = {
        ftn: this.ftn,
        dob: this.dob,
        email: this.userData.mail || this.userData.sub
      }
      this.agentDetailsService.GetExternalUserAgentsDetails(reqData).subscribe((data) => {
        if (data) {
          this.count++;
          if (data.message === "User not found." || data.message ==="Airmen details not found") {
            if (this.count === 2) {
              this.count = 0;
              this.UpdateFtnForExternalUser();
              this.addAgent();
              this.restForm();
            } else {
              this.isError = true;
              this.ftnSearch.reset();
              this.errorMsg = `Your FTN is not known to the USAS database. If you recently created your FTN, please click submit again to create a new U.S. Agent for Service.`;
            }
          }
          else if (data.message === "Another User/Email is already associated to the FTN you have entered. Please check your FTN or Email and resubmit your request." ||
            data.message === "The FTN or DOB does not match with your registered email. Please check your FTN or DOB and resubmit your request."
          ) {
            this.isError = true;
            this.ftnSearch.reset();
            this.errorMsg = data.message;
          }
          else {
            if (data.agentDetails == null) {
              this.UpdateFtnForExternalUser();
              this.addAgent();
              this.restForm();
            } else {
              this.UpdateFtnForExternalUser();
              this.agentDetails = data.agentDetails;
              this.showEditMode = true;
              sessionStorage.setItem('showEditMode', "true"); 
              sessionStorage.setItem('ftn', this.ftn); 
              sessionStorage.setItem('dob', this.dob); 
            }
          }
        }
      });
    }
    else if (!this.ftnSearch.valid && !sessionStorage.getItem('showEditMode')) {
      this.ftnSearch.markAllAsTouched();
    }

  }
  restForm(){
    this.isError = false;
    this.errorMsg = '';
    sessionStorage.setItem('ftn', this.ftn); 
    sessionStorage.setItem('dob', this.dob);
    this.ftnSearch.reset();
  }

  //New API call to update FTN only while updating an agent
  UpdateFtnForExternalUser(){
    const reqData = {
      ftn: this.ftn,
      dob: this.dob,
      email: this.userData.mail || this.userData.sub
    }
    this.agentDetailsService.AddUpdateApplicantDetails(reqData).subscribe((data) => {
        if(data)
        {
          this.applicantId = data;
        }
      });
  }
}

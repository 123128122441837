import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { AddInternalUserModalComponent } from '../add-internal-user-modal/add-internal-user-modal.component';

interface Dataset {
  firstname: String,
  middlename: String,
  lastname: String,
  email: String
}

@Component({
  selector: 'app-add-internal-user-search-list',
  templateUrl: './add-internal-user-search-list.component.html',
  styleUrl: './add-internal-user-search-list.component.scss'
})
export class AddInternalUserSearchListComponent {
  @Input() userSearchResults:any
  users: Dataset[] = [];
  constructor(public router: Router, private userDetailService: UserDetailsService,
    public userDialogRef: DynamicDialogRef, private messageService: MessageService, 
    private dialogService:DialogService,) {};

  ngOnInit() {
    if(this.userSearchResults && this.users.length<=0){
      this.users.push(this.userSearchResults) ;  
    }
  }

  ngOnChanges(changes:any) {
    this.users = [];
    this.users.push(changes.userSearchResults.currentValue);
  }

  addNewUser(event:Event, userData:any){
    this.userDetailService.assignRole = true;
    this.userDetailService.selectedUserData = userData;
    this.userDialogRef = this.dialogService.open(AddInternalUserModalComponent, {
      header: 'New Internal User',
      width: 'fit-content',
      contentStyle: {'padding': '25px 25px 25px 25px'},
      height: 'auto'
    });
    this.userDialogRef.onClose.subscribe((formData) => {
      this.router.navigate(["/manage-users"]);
      const internalUserForm = formData.formData;
      if(internalUserForm && internalUserForm.status !== "INVALID" && !formData.isCancel){
      const selectedUser = this.userDetailService.selectedUserData;
      const data = 
      {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        isActive: internalUserForm.controls['selectedStatus'].value === 'Active' ? 'Y' : 'N',
        userRole: {
          roleId: internalUserForm.controls['selectedRole'].value
        },
      }
      this.userDetailService.addUser(data).subscribe((results)=>{
        if(results.message === 'Success'){
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'User has been added successfully and required privileges /access has been provided',
          });
          }
          if(results.message === 'User already exist'){
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'User already exists',
            });
          }
          if(results.message ==='System shall have at least one active admin at all time'){
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: results.message,
            });
          }
        })       
    }   
  })
    event.preventDefault();
  }
}

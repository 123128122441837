import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomLoginService } from '../../shared/services/custom-login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {

  formGroup: FormGroup;
  userForm: FormGroup
  passwordError: string;
  newPasswordError: any;
  confirmPasswordError: string;
  passwrodChanged: boolean = false;
  isFormValid: boolean = false;
  email:string

  constructor(private fb: FormBuilder, private customLoginService: CustomLoginService, private messageService: MessageService, private route :ActivatedRoute, public router: Router) {
  }

  ngOnInit(): void {
    this.VerifyResetPasswordLink()
    this.userForm = this.fb.group({
      newPassword: this.fb.control('', Validators.required),
      confirmPassword: this.fb.control('', Validators.required),
    });
  }

  validatePassword(type: string, control: string) {
    this.isFormValid = false;
    const password = this.userForm.controls[control].value;
    console.log('password');
    let errorMessage = '';
 

    // Check if the passwords match
    if (type === 'confirmPassword') {
      if (password === '') {
        errorMessage = 'Confirm Password is required.';
      } else if((this.userForm.controls['newPassword'].value !== '') 
        && (password !== this.userForm.controls['newPassword'].value)) {
        errorMessage = 'Password and confirm password do not match.';
      } else if ((password.value !== '') && (this.userForm.controls['newPassword'].value !== '') 
        && password.value === this.userForm.controls['newPassword'].value && password.valid) {
        errorMessage = '';
      }
    } else {
      if (password === '') {
        errorMessage = 'Password is required.';
      } else if (password.length < 12 || password.length > 24) { // Check length requirement
        errorMessage = 'Password must contain between 12 and 24 characters';
      } else if (!/^[a-zA-Z]/.test(password)) { // Check if the password begins with a letter
        errorMessage = 'Password must begin with a letter';
      } else if (/([a-zA-z0-9]|[!#$%])\1/.test(password)) { // Check for no repeated characters in sequence OR (\w|[!#$%])\1
        errorMessage = 'No character may be repeated in sequence';
      } else {
        // Check for required character types
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!#\$%]/.test(password);
        const hasOtherChar = /^[a-zA-Z0-9!#$%]+$/.test(password);
      
        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || !hasOtherChar) {
          errorMessage = 'Password must include four types of characters: English upper case letter, English lower case letter, number, and allowable special characters (!, #, $, %)';
        } else if ((this.userForm.controls['confirmPassword'].value !== '') 
          && (password !== this.userForm.controls['confirmPassword'].value)) {
          errorMessage = 'Password and confirm password do not match.';
        } else if ((this.userForm.controls['confirmPassword'].value !== '') && (password.value !== '') 
          && this.userForm.controls['confirmPassword'].value === password.value && password.valid) {
          errorMessage = '';
        }
      }
    }

    if(errorMessage.length) {
      if(type === 'newPassword') {
        this.newPasswordError =  errorMessage;
        this.userForm.controls.newPassword.setErrors({ incorrect: true });
      } else {
        this.confirmPasswordError =  errorMessage;
        this.userForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
    } else {
      if(type === 'newPassword') { 
        this.userForm.controls.newPassword.setErrors(null);
      }  if ((this.userForm.controls['confirmPassword'].value !== '') && (this.userForm.controls['newPassword'].value !== '') 
        && (this.userForm.controls['confirmPassword'].value === this.userForm.controls['newPassword'].value) && (this.userForm.controls['newPassword'].valid || this.newPasswordError ==='Password and confirm password do not match.')) {
          this.userForm.controls.confirmPassword.setErrors(null);
          this.userForm.controls.newPassword.setErrors(null);
          this.isFormValid = this.userForm.valid;
        }
      if(this.userForm.controls['confirmPassword'].value ===''){
        this.userForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
      if(this.userForm.controls['newPassword'].value ===''){
        this.userForm.controls.newPassword.setErrors({ incorrect: true });
      }
    }
    this.userForm.updateValueAndValidity();
    // this.isFormValid = this.userForm.valid;
  }

  updatePassword() {
    console.log("Submit Change Password");
    const userObj = {
      "email": this.email, 
      "password": this.userForm.controls.newPassword.value
    };
    this.customLoginService.resetPassword(userObj).subscribe(
      (data) => {
        if (data && data.status) {
          this.passwrodChanged = true;
        } else {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Unable to Change Password',
            detail: data.message,
          });
        }
      },
      (error: any) => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Error Changing Password',
          detail: error,
        });
      }
    );
  }
  
  VerifyResetPasswordLink(){
    const securityCode = this.route.snapshot.queryParams['code'];
      const data = {
        securityCode: securityCode
      }
      this.customLoginService.VerifyResetPasswordLink(data).subscribe(
        (data) => {
          this.email = data.email;
        },
        (error: any) => {
         
        }
      );
  }

  goToDisclaimer() {
    this.router.navigateByUrl('/disclaimer');
  }

}

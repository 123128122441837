<form [formGroup]="searchForm">
    <div class="grid">
        <div class="form-grid grid">
            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                <label for="firstName">First Name</label>
                <input type="text" id="firstName" formControlName="firstName"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
            </div>
            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                <label for="lastName">Last Name</label>
                <input type="text" id="lastName" formControlName="lastName"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
            </div>
            <div class="form-group field sm:col-12 md:col-4 lg:col-3">
                <label for="email">Email</label>
                <input type="email" id="email" formControlName="email"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" />
                <!-- <div *ngIf="searchForm.get('email').hasError('required')">
                Email is required.
            </div>
            <div *ngIf="searchForm.get('email').hasError('email')">
                Please provide a valid email address.
            </div> -->
            </div>
            <div class="form-group field sm:col-12 md:col-4 lg:col-3" *ngIf="searchType == 'InternalUser'">
                <label for="userrole">Select Role</label>
                <p-dropdown id="userrole" formControlName="selectedRole" [options]="userRoles" optionLabel="roleName" optionValue="roleId" [style]="{'height':'36px', 'width':'240px'}"></p-dropdown>
            </div>
        </div>
        <div class="form-grid grid" *ngIf="searchType == 'Airman/Operator'">
            <div class="form-group field sm:col-12 md:col-4 lg:col-4">
                <label for="startDate">Updated from Date</label>
                <p-calendar [maxDate]="dateTime" id="startDate" formControlName="startDate" ariaLabelledBy="startDate"
                    placeholder="mm/dd/yyyy" [iconDisplay]="'input'" [showIcon]="true" [selectOtherMonths]="true"
                    [readonlyInput]="true"></p-calendar>
            </div>
            <div class="form-group field sm:col-12 md:col-4 lg:col-4">
                <label for="endDate">Updated to Date</label>
                <p-calendar [maxDate]="dateTime" id="endDate" formControlName="endDate" ariaLabelledBy="endDate"
                    placeholder="mm/dd/yyyy" [iconDisplay]="'input'" [showIcon]="true" [selectOtherMonths]="true"
                    [readonlyInput]="true"></p-calendar>
            </div>
        </div>
    </div>
    <div class="grid">
        <button type="submit" class="primary default" (click)="handleSearch()">Search</button>
        <button class="secondary default" (click)="clearSearch()">Clear</button>
    </div>
</form>
<!-- Strating user idle page -->
<!-- Shows the dialog time out after time is less then 5mins -->
<ng-container *ngIf="(minutesDisplay <= timeoutWarning-1) && (secondsDisplay) && (secondsDisplay <=60)">
    <!-- Session time out dialog -->
    <p-dialog header="Session Time Out Alert" [(visible)]="display" [modal]="true" [style]="{width: '280px', minWidth: '400px'}" [closable]="false"
            [draggable]="false" [resizable]="false" (onShow)="showTabBlink()">            
    <!-- Session time out message -->
    <!-- <span>  Your session will expire in {{ minutesDisplay}}:{{ secondsDisplay && secondsDisplay <=60 ? secondsDisplay : '00' }} seconds. Any unsaved data will be lost.
    </span> -->
    <div class="flex flex-column px-8 py-5 gap-4">
        <i class="pi pi-clock m-auto" style="font-size: 2.5rem" aria-label="alert clock icon" role="img"></i>
    </div>
    <p class="text-center">
        Your session will expire in {{ minutesDisplay}}:{{ secondsDisplay && secondsDisplay <=60 ? secondsDisplay : '00' }} seconds. Any unsaved data will be lost.
    </p>
    <!-- Session time dialog action items NEED TO UNCOMMENT WHEN API IS READY -->
    <ng-template pTemplate="footer">
        <div class="flex justify-content-center gap-2">
            <button pButton (click)="extendTime()" label="Extend my session" class="primary default"></button>
            <button type="button" pButton (click)="logoutUser($event)" label="Sign out" class="secondary default"></button>
        </div>
    </ng-template>
  </p-dialog>
  <!-- Dialog end -->
  </ng-container>
  <!-- Page end -->
  
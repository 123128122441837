import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoggerService } from '../shared/services/logger/logger.service';
//import { Privileges } from '../shared/privileges-constants';
import { UserDataService } from '../shared/services/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(
    private messageService: MessageService,
    private logger: LoggerService,
    private userDataService: UserDataService,
  ) { }

  // isAuthorizedWithMessage(privilege: any, noAccessErrorMessage: any): boolean {
  //   this.logger.debug('Inside isAuthorizedWithMessage ' + privilege);

  //   let isAuthorized;
  //   isAuthorized = this.isAuthorized(privilege);

  //   if (!isAuthorized) {
  //     this.messageService.clear();
  //     this.messageService.add({
  //       severity: 'error',
  //       summary: 'Access Denied"',
  //       detail: noAccessErrorMessage,
  //     });
  //   }
  //   this.logger.debug(' isAuthorizedWithMessage for ' + privilege + '  = ' + isAuthorized);
  //   return isAuthorized;
  // }

  // isAuthorized(privilege: any): boolean {
  //   this.logger.debug('Inside isAuthorized ' + privilege);

  //   let isAuthorized;
  //   let userPrivileges = sessionStorage.getItem('userPrivs');
  //   if (userPrivileges) {
  //     userPrivileges = JSON.parse(userPrivileges);
  //     if (userPrivileges.indexOf(privilege) !== -1) {
  //       isAuthorized = true;
  //     } else {
  //       isAuthorized = false;
  //     }
  //   }
  //   this.logger.debug(' isAuthorized for ' + privilege + '  = ' + isAuthorized);
  //   return isAuthorized;
  // }


  displayErrorMessage(noAccessErrorMessage: any): void {
    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      summary: 'Access Denied',
      detail: noAccessErrorMessage,
    });
  }
}

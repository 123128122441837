import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../../app-config.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { AuthService } from '../../_auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgentDetailsService {

  _appSettings;

  constructor( private httpClient: HttpClient, private logger: LoggerService, 
    private authService: AuthService) {
    this._appSettings = AppSettings;
   }

   public saveAgentDetails(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}Agent/AddorUpdateAgent`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getAgentDetails(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}Agent/GetAgentDetails`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public GetExternalUserAgentsDetails(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/GetExternalUserAgentsDetails`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public AddUpdateApplicantDetails(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}ExternalUser/AddUpdateApplicant`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  errorHandler(error: any): any {
    this.logger.error(error);
    return throwError(new Error(error));
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-internal-user-search',
  templateUrl: './add-internal-user-search.component.html',
  styleUrl: './add-internal-user-search.component.scss'
})
export class AddInternalUserSearchComponent {
  searchForm: FormGroup;
  userSearchResult : any;

  @Output() newUserSearchResults: EventEmitter<any> = new EventEmitter();
  constructor(private fb: FormBuilder, private userDetailService: UserDetailsService,public router: Router,) {}
  
// Email Validation Regex breakdown
// ^: This asserts the start of the string.
// ((?!\s)[a-zA-Z._%+-]+[\w+-.]+(?:\.\w+)?): This part matches the username portion of an email address:

// (?!\s): Ensures that the username does not start with a space.

// [a-zA-Z._%+-]+: Matches one or more characters from the set [a-zA-Z._%+-].

// [\w+-.]+: Matches one or more word characters (letters, digits, or underscores), plus hyphens and dots.

// (?:\.\w+)?: Optionally matches a dot followed by one or more word characters (the domain part).

// @: Matches the “@” symbol.

// ([\w-]+(?:\.[a-zA-Z]{2,10})+): This part matches the domain portion of an email address:

// [\w-]+: Matches one or more word characters or hyphens (the domain name).

// (?:\.[a-zA-Z]{2,10})+: Matches one or more occurrences of a dot followed by 2 to 10 letters (the top-level domain).

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.pattern(/^((?!\s)[a-zA-Z][\w+-.]+(?:\.\w+)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/)])
    });
  }
  clearErrorChange()
  {
    this.userSearchResult = '';    
  }
  handelSearch(){
    const data = 
    {
      skip: 0,
      take: 100,
      email: this.searchForm.controls['email'].value
    }
    this.userSearchResult = "";
    if (!this.searchForm.valid){
      this.searchForm.markAllAsTouched();
    } else {
      this.userDetailService.getSearchUsersLDAP(data).subscribe((results)=>{
        if (results.message !== "Success")
        {
          this.userSearchResult = results.message;
        }
          this.newUserSearchResults.emit(results);
      })
    }
  }
  
  cancelSearch(){
    this.userSearchResult = "";
    this.searchForm.reset();
  }
}

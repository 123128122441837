import { Component } from '@angular/core';

@Component({
  selector: 'app-internal-user-page',
  templateUrl: './internal-user-page.component.html',
  styleUrl: './internal-user-page.component.scss'
})
export class InternalUserPageComponent {
  public searchResults:any;
  handleSearch(event:any){
    this.searchResults = event;
  }
}

<div *ngIf="routerChanged">
  <app-usas-loading></app-usas-loading>
</div>
<app-user-idle-inactive-timeout></app-user-idle-inactive-timeout>
<faa-header></faa-header>
<!--<faa-nav-bar></faa-nav-bar>-->
<ng-container>
  <div style="min-height: 750px; height: 100%;">
    <main class="devkit-main-wrapper devkit-no-border m-auto grid-container" id="main-content" style="max-width: 80%;">
      <div class="devkit-main-content-wrapper"> 
        <div class="flex-container shadow-light-sm m-top-md">
          <div class="flex-50 bg-dark-blue align-center">
            <img src="../assets/plane-img.png" alt="plane-image" width="auto">
          </div>
          <div class="flex-auto bg-light padding-md top-border-dark">
            <div class="devkit-main-head">
              <h1>Coming Soon</h1>
            </div>
            <p style="font-size: 1.25rem;">Thank you for visiting USAS portal to designate your U.S. Agent</p>
            <ul>
                <li>
                    The USAS portal is currently under development.
                    Please check back again for go live date and other updates.
                </li>
                <!-- <li>
                    For more information about the proposed rule 
                    <span><a href="https://www.federalregister.gov/documents/2023/06/12/2023-12124/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" target="_blank">
                      click here</a></span>
                </li> -->
                <li>
                  <strong>Application/Certification requirements have changed for individuals who have a foreign address and DO NOT have a U.S. physical address of record on file with the FAA.</strong>
                  <br>
                  On October 8, 2024, FAA published final rule 89 FR 81305, U.S. Agents for Service on Individuals with Foreign Addresses Who Hold or Apply for Certain Certificates, Ratings, or Authorizations, with new requirements added to Title 14 CFR part 3 subpart C. Individuals who have a foreign address and no U.S. physical address of record on file with the FAA are required to designate a U.S. agent for service if they apply for a certificate issued under 14 CFR part 47, 61, 63, 65, 67, or 107 or hold a certificate issued under any of these parts. This requirement applies only to individuals (not entities).  
                  <br>
                  The compliance dates for this final rule are as follows: January 6, 2025, for applicants of any certificate, rating, or authorization issued under part 47, 61, 63, 65, 67, or 107, and July 7, 2025, for holders of any certificate, rating, or authorization issued under part 47, 61, 63, 65, 67, or 107.
                </li>
                <li>
                  Click here <span><a href="https://amsrvs.registry.faa.gov/amsrvs/" target="_blank" 
                    style="text-decoration: underline;"> Airman services</a></span> to view and modify your address of record.
                </li>
            </ul>
          </div>
        </div>        
      </div>
    </main>
  </div>
</ng-container>
<div class="p-col-12">
  <faa-footer [footerLandingFlag]="landingPageFlag"></faa-footer>
</div>
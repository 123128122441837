import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './devkitcomponents/header/header.component';
import { TopBarComponent } from './devkitcomponents/top-bar/topBar.component';
import { SiteLogoComponent } from './devkitcomponents/header/site-logo/siteLogo.component';
import { ToolBarComponent } from './devkitcomponents/header/tool-bar/toolBar.component';
import { SearchBarComponent } from './devkitcomponents/header/search-bar/searchBar.component';
import { MobileSearchBarComponent } from './devkitcomponents/header/mobile-search/mobileSearchBar.component';
import { UserGreetingComponent } from './devkitcomponents/header/user-greeting/userGreeting.component';
import { HamburgerMenuComponent } from './devkitcomponents/header/hamburger-menu/hamburgerMenu.component';
import { LeftNavComponent } from './devkitcomponents/left-nav/left-nav.component';
import { NavBarComponent } from './devkitcomponents/nav-bar/navBar.component';
import { FooterComponent } from './devkitcomponents/footer/footer.component';
import { FooterLinksComponent } from './devkitcomponents/footer/footer-links/footerLinks.component';
import { FooterLogoComponent } from './devkitcomponents/footer/footer-logo/footerLogo.component';
import { BottomBarComponent } from './devkitcomponents/bottom-bar/bottom-bar.component';
import { SecurityWarningComponent } from './appcomponents/security-warning/security-warning.component';
import { LandingPageComponent } from './appcomponents/landing-page/landing-page.component';
import { LoginComponent } from './appcomponents/login/login.component';
import {RouterModule, UrlSerializer} from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { AddAgentModalComponent } from './appcomponents/add-agent-modal/add-agent-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import {TooltipModule} from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import {  DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthGuardService } from './_auth/auth-guard.service';
import { AuthService } from './_auth/auth.service';
import { UserDataService } from './shared/services/user-data.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { USASHttpInterceptorService } from './shared/services/USASHttpInterceptorService';
import { UsasLoadingComponent } from './shared/components/usas-loading/usas-loading.component';
import { FormatPhoneNumberPipe } from './shared/pipes/format-phone-number.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { AccessDeniedComponent } from '../app/appcomponents/access-denied/access-denied.component';
import { InputMaskModule } from 'primeng/inputmask';
import { SearchpanelComponent } from './appcomponents/searchpanel/searchpanel.component';
import { SearchResultsComponent } from './appcomponents/search-results/search-results.component';
import { InternalLandingPageComponent } from './appcomponents/internal-landing-page/internal-landing-page.component';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { AddInternalUserModalComponent } from './appcomponents/add-internal-user-modal/add-internal-user-modal.component';
import { InternalUserListComponent } from './appcomponents/internal-user-list/internal-user-list.component';
import { InternalUserPageComponent } from './appcomponents/internal-user-page/internal-user-page.component';
import { AddInternalUserSearchListComponent } from './appcomponents/add-internal-user-search-list/add-internal-user-search-list.component';
import { AddInternalUserSearchComponent } from './appcomponents/add-internal-user-search/add-internal-user-search.component';
import { AddInternalUserPageComponent } from './appcomponents/add-internal-user-page/add-internal-user-page.component';
import { SkeletonModule } from 'primeng/skeleton';
import { UserAccountComponent } from './appcomponents/user-account/user-account.component';
import { ChangePasswordComponent } from './appcomponents/change-password/change-password.component';
import { PasswordModule } from 'primeng/password';
import { ForgotPasswordComponent } from './appcomponents/forgot-password/forgot-password.component';
import { ConfirmVerifcationComponent } from './appcomponents/confirm-verifcation/confirm-verifcation.component';
import { UserIdleInactiveTimeoutComponent } from './appcomponents/user-idle-inactive-timeout/user-idle-inactive-timeout.component';
import { FaqListComponent } from './appcomponents/faq-list/faq-list.component';
import { ContactUsComponent } from './appcomponents/contact-us/contact-us.component';
import { MenuModule } from 'primeng/menu';
import { CustomUrlSerializer } from './shared/services/custom-rul-serialize';
import { AccountInfoComponent } from './appcomponents/account-info/account-info.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    FooterLinksComponent,
    FooterLogoComponent,
    HeaderComponent,
    TopBarComponent,
    SiteLogoComponent,
    ToolBarComponent,
    SearchBarComponent,
    MobileSearchBarComponent,
    UserGreetingComponent,
    HamburgerMenuComponent,
    LeftNavComponent,
    NavBarComponent,
    BottomBarComponent,
    SecurityWarningComponent,
    LandingPageComponent,
    AddAgentModalComponent,
    LoginComponent,
    UsasLoadingComponent,
    FormatPhoneNumberPipe,
    AccessDeniedComponent,
    SearchpanelComponent,
    SearchResultsComponent,
    InternalLandingPageComponent,
    AddInternalUserModalComponent,
    InternalUserListComponent,
    InternalUserPageComponent,
    AddInternalUserSearchListComponent,
    AddInternalUserSearchComponent,
    AddInternalUserPageComponent,
    UserAccountComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ConfirmVerifcationComponent,
    UserIdleInactiveTimeoutComponent,
    FaqListComponent,
    ContactUsComponent,
    AccountInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    DialogModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    CheckboxModule,
    TooltipModule,
    ToastModule,
    DropdownModule,
    AccordionModule,
    HttpClientModule,
    InputMaskModule,
    CalendarModule,
    TableModule,
    SkeletonModule,
    PasswordModule,
    MenuModule
  ],
  exports: [
    DropdownModule
  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: USASHttpInterceptorService, multi: true },
    {provide: UrlSerializer, useClass: CustomUrlSerializer},
    DialogService,
    MessageService,
    DynamicDialogRef,
    AuthGuardService,
    AuthService,
    UserDataService,
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
<p-toast position="top-center"></p-toast>
<p-table [tableStyle]="{'min-width': '60rem'}" [value]="users" [resizableColumns]="true"
    [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [totalRecords]="20" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-striped p-datatable-gridlines mt-4" responsiveLayout="scroll" role="table">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
           SEARCH RESULTS
        </div>
    </ng-template>
    <ng-template pTemplate="header" class="bg-blue-700">
        <tr class="devkit-table-row bg-blue-700">
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr class="devkit-table-row" role="row">
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.email}}</td>
            <td>
                <a href="" rel="noopener noreferrer" class="pl-0 p-button p-button-link" (click)="addNewUser($event, user)">Select to assign role</a>
            </td>
        </tr>
    </ng-template>
</p-table>
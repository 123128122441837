<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2" *ngIf="userData?.userIsActive !== 'Y'">
            <button type="button" class="secondary default" (click)="signin()">Back To Sign In</button>
        </div>
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>FAQs</h1>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <p-accordion class="text-sm">
                    <p-accordionTab header="What is a U.S. Agent for Service?">
                        <ul>
                            <li>
                                A U.S. agent for service is an entity who a certificate holder or applicant designates to receive FAA service on their behalf.
                            </li>
                            <li>
                                A U.S. agent for service is an adult (18 or older) who a certificate holder or applicant designates to receive FAA service on their behalf.
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="What actions does the U.S. Agent for Service perform?">
                        <p>Provide the description and link to the law/policy</p>
                    </p-accordionTab>
                    <p-accordionTab header="Why do I have to create a U.S. Agent?">
                        <ul>
                            <li>
                                You have been identified as a registrant or certificate holder with a non-US physical address of record with the FAA
                            </li>
                            <li>
                                If you need to correct your address of record, click (HERE) to go to Airmen Services to update your information
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="I have a US physical address, why was I told to register a U.S. Agent for Service?">
                        <ul>
                            <li>
                                The FAA reviewed all addresses of record and determined you do not have a US physical address.
                            </li>
                            <li>
                                If this is incorrect, please click (here) to go to Airmen Services.
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="Who should have/create an account in USAS?">
                        <p>Applicants, Airmen, and other FAA employees involved in U.S. Agent of Service process can request access to USAS.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Are all the Airmen required to use USAS?">
                        <p>All the Airmen are not required to use USAS. Only the individuals who hold or apply for certificates issued under 14 CFR parts 47, 61, 63, 65, 67, or 107 and who do not have a U.S. physical address of record on file with the FAA to designate a U.S. agent. The U.S. Agent would receive service of FAA documents on behalf of the applicant or certificate holder.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Is there any required information needed before designating a U.S. Agent in USAS?">
                        <p>All Airmen coming to USAS to designate a U.S. Agent will need to have a their FTN/DOB. Aircraft Owners will need to have their NNumber and Series Number.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Can you login to USAS if you don’t have an FTN?">
                        <p>If you have an FTN, as a MedXPress user please retrieve your FTN from your MedXPress profile and if you are an IACRA user, please retrieve your FTN from your IACRA user profile. If you don’t have an FTN, please procced to create an account in <span><a href="https://iacra.faa.gov/IACRA/Default.aspx">IACRA IACRA - Federal Aviation Administration (faa.gov)</a></span> prior to logging into USAS.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Is there any fee required?">
                        <ul>
                            <li>
                                There are no fees charged by the FAA to provide you U.S. Agent for Service.
                            </li>
                            <li>
                                A U.S. Agent for Service company may charge fees for their services, but these fees are not controlled by the FAA. The FAA does not charge any fees for registering your U.S. Agent for Service.
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="I forgot my username and/or password?">
                        <ul>
                            <li>
                                Resetting your password is easily accomplished online.
                            </li>
                            <li>
                                Please <span><a class="font-medium no-underline link-button cursor-pointer ml-0" 
                                    (click)="forgotPassword()" aria-label="Link to reset password" title="Reset Password">click here</a></span>to reset your password
                            </li>
                        </ul>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>

<div class="security-warning-wrapper">
  <div class="security-message">
    <div class="diws-block diws-center">
      Warning --- Warning --- Warning
    </div>
    <div class="diws-block diws-center">
      For Official Use Only. Public availability to be determined under 5 U.S.C. 552
    </div>
    <div class="diws-block diws-center">
      Only Authorized Users May Use This System
    </div>
    <div class="diws-block diws-text-bold diws-center">
      System Use Notice
    </div>
    <div class="diws-block">
      You are accessing a U.S. Government authorized information system, which includes (1) this computer, (2) this computer network,
      (3) all computers connected to this network, (4) all devices and storage media attached to this network or to a computer on this network, and
      (5) all cloud services and hosting environments supporting this information system. This information system is provided for U.S. Government-authorized use only.
    </div>
    <div class="diws-block">
      Unauthorized or improper use of this system may result in disciplinary action, as well as civil and criminal penalties.
    </div>
    <div class="diws-dble-block">
      By logging in and using this information system, you understand and consent to the following:
    </div>
    <ul>
      <li>
        You have no reasonable expectation of privacy regarding communications or data transiting or stored on this information system.
      </li>
      <li>
        At any time, and for any lawful Government purpose, communication between the user and this information system, data transiting to/from the system, or stored on this system is subject to monitoring, interception, and search.
      </li>
      <li>
        Any communications or data transiting or stored on this information system may be disclosed or used for any lawful Government purpose.
      </li>
    </ul>
  </div>
  <button
      class="primary default"
      type="button"
      (click)="accessDIWS()"
    >Accept</button>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_auth/auth.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {

  userData:any;

  constructor(public router: Router, private authService: AuthService){}
  ngOnInit(): void {
    this.userData = this.authService.getUserData()
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }

  signin() {
    this.router.navigateByUrl('/signin');
  }
}

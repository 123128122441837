import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StateService } from '../../shared/services/agent-state.service';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { MessageService } from 'primeng/api';

interface Role {
  title: string;
}

interface Status {
  status: string;
}

@Component({
  selector: 'app-add-internal-user-modal',
  templateUrl: './add-internal-user-modal.component.html',
  styleUrl: './add-internal-user-modal.component.scss'
})
export class AddInternalUserModalComponent implements OnInit {

  internalUserForm: FormGroup;
  userRoles: any[];
  userStatus: any[];
  userData: any;
  selectedUser:any;
  disableBtn:boolean = true;
    
  constructor(private fb: UntypedFormBuilder, private config: DynamicDialogConfig, private cdr: ChangeDetectorRef,
    private ref: DynamicDialogRef, private stateService: StateService, private userDetailService: UserDetailsService) {

    this.userData = config?.data;

    this.userData = this.fb.group({

    })
  }

  ngOnInit(): void {
    this.selectedUser = this.userDetailService.selectedUserData;
    if(this.userDetailService.listOfRoles && this.userDetailService.listOfRoles.length >0){
      this.userRoles = this.userDetailService.listOfRoles;
    }else{
      this.userDetailService.getAllRoles().subscribe((data)=>{
        this.userDetailService.listOfRoles = data;
        this.userRoles = data;
      });
    }
  
    this.userStatus = [
      {status: 'Active'},
      {status: 'Inactive'}
    ];

    this.internalUserForm = this.fb.group({
      selectedRole: this.fb.control(''),
      selectedStatus: this.fb.control('')
    });
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.internalUserForm.patchValue({'selectedRole':this.selectedUser.userRole.roleId});
      this.internalUserForm.patchValue({'selectedStatus':this.selectedUser.isActive=='Y'?'Active':'Inactive'});
      this.cdr.detectChanges();
      this.disableBtn = true;
    },0)
  }
  onChange(event:any) {
    console.log(event);
    if(this.selectedUser.userRole && this.selectedUser.userRole.roleId){
      if(event.value !== this.selectedUser?.userRole?.roleId || event.value !== this.selectedUser?.isActive){
        this.disableBtn = false;
      }
    }else if(this.internalUserForm.controls['selectedRole'].value && this.internalUserForm.controls['selectedStatus'].value){
      this.disableBtn = false
    }
  }

  addUser(event?:Event){
    if (this.internalUserForm.valid ) {
      this.closeAgentModal(false, event);  
    }else if (!this.internalUserForm.valid){
      this.internalUserForm.markAllAsTouched();
    }
  }

  closeAgentModal(isCancel?:boolean, event?:Event) {
    this.ref.close({formData:this.internalUserForm,isCancel:isCancel, event:event});
    this.userDetailService.assignRole = false;
    event?.preventDefault();
  }
}
import { HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent } from '@angular/common/http';
import { AuthService } from '../../_auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class USASHttpInterceptorService implements HttpInterceptor {
  validToken: Boolean = false;

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    let nextReq:HttpRequest<any> | undefined;
      if (req.url === '/ExternalUser/Login' || req.url === '/ExternalUser/ChangeUserPassword' || req.url === 'Lookup/GetAllSecurityQuestions' || req.url === ' ExternalUser/UserRegistration' ||
      req.url === '/ExternalUser/ResetPassword' || req.url === '/ExternalUser/VerifyUser' || req.url === '/ExternalUser/VerifyAndGenerateLink' || 
      req.url === '/ExternalUser/ConfirmUserActivation' || req.url === '/ExternalUser/VerifyResetPasswordLink'
      ) {
        nextReq = req.clone({
          setHeaders: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Content-Type' : 'application/json',
          },
        });
      } else  {
        const headers = this.authService.getHeadersParams();
        nextReq = req.clone({
          setHeaders: headers
        });
      }
    return next.handle(nextReq as HttpRequest<any>);
  }
}




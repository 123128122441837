<div *ngIf="isLoading">
  <app-usas-loading></app-usas-loading>
</div>
<p-toast position="top-center"></p-toast>

<main id="main-content" class="grid nested-grid">
  <div class="col-10 xl:col-10 lg:col-10 md:col-12 sm:col-12 mt-4 px-6" *ngIf="!showEditMode">
    <h1>U.S. Agent Information</h1>
    <div class="border-round-lg p-4 h-auto bg-dark-cream border-top-3 border-yellow-600">
      <div class="grid">
        <div class="col-8">
          <h2>Get started by managing your U.S. Agent</h2>
          <div *ngIf="!isError">
            <p>The current FAA rule requires individuals with foreign addresses to designate a U.S. Agent for service of FAA documents. Please click the rulemaking link for further information-<a type="button" class="link-button ml-0" href="#devkit-skip-to-main" aria-label="rulemaking website" title="IACRA Website">Click here</a></p>
            <p class="font-bold">The FAA uses a FAA Tracking number (FTN) to uniquely identify each person taking actions on many FAA systems. You may already have one of these unique identifiers assigned to you. <span class="text-yellow-700">You must know your unique identifier and date of birth to assign a U.S. Agent for Service.</span></p>
            <p class="text-sm mt-5">Please enter FTN and DOB to designate/update your U.S. Agent</p>
          </div>
          <div *ngIf="isError">
            <p>The FAA uses FTN to uniquely identify each person taking actions on many FAA systems. 
              You may already have the unique identifier assigned to you. You must know your unique identifier and date of birth to assign a U.S. Agent for Service.</p>
            </div>

          <p style="color: #c32026" id="errorMessage" class="text-sm pr-3" *ngIf="isError">
            <span><i class="fa-solid fa-triangle-exclamation" style="color: #c32026;"></i></span>
            {{errorMsg}}
          </p>

          <div class="form-items-wrapper" [formGroup]="ftnSearch">
            <div class="form-item-wrapper" style="margin-right: 30px;">
              <label for="ftnNumber">FTN<span class="asterik">*</span></label>
              <input pInputText id="ftnNumber" type="text" maxLength="8" formControlName="ftnNumber"
                class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full" style="height: 34px;" required />
                <div *ngIf="ftnSearch?.get('ftnNumber')?.invalid  && ftnSearch?.get('ftnNumber')?.errors['required'] && ftnSearch?.get('ftnNumber')?.touched"
                            class="alert danger-alert error-font">FTN is required. </div>
                            <div class="alert danger-alert error-font" *ngIf="ftnSearch?.get('ftnNumber')?.errors && ftnSearch?.get('ftnNumber')?.errors['pattern']">
                                Please enter valid FTN.
                            </div>
            </div>
            <div class="form-item-wrapper">
              <label for="dobEntry" style="display: block;">DOB<span class="asterik">*</span></label>
              <p-calendar placeholder="mm/dd/yyyy" formControlName="dob" [iconDisplay]="'input'" [showIcon]="true" 
              [selectOtherMonths]="true" [maxDate]="maxdobDate"/>
              <div *ngIf="ftnSearch?.get('dob')?.invalid  && ftnSearch?.get('dob')?.errors['required'] && ftnSearch?.get('dob')?.touched" class="alert danger-alert error-font">DOB is required. </div>
            </div>
          </div>

          <div>
            <button type="submit" class="primary default" (click)="handleFtnSubmit()">Submit</button>
            <button class="secondary default" (click)="restForm()">Clear</button>
          </div>

          <div class="devkit-info-message-wrapper mt-3" role="dialog" aria-label="info-message" aria-describedby="info-message-body">
            <div class="devkit-message-body-wrapper">
              <div class="devkit-message-body" id="info-message-body">
                <b>Note:</b> Visit<span><a type="button" class="link-button" href="#devkit-skip-to-main" aria-label="iacra website"
                  title="IACRA Website">IACRA.faa.gov</a></span>to either register for a new FTN number or to look up the
                FTN on your account profile. Then return here to complete your application.
                <ul>
                  <li>MedXPress Airman - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                      href="#devkit-skip-to-main" aria-label="Redirect to MedXPress profile"
                      title="Redirect to MedXPress profile" target="_blank">Click here</a></span>to look up your FTN number in your MedXPress Profile
                  </li>
                  <li class="mt-2">IACRA Airman/Operator - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                      href="#devkit-skip-to-main" aria-label="Link to IACRA Profile"
                      title="Link to IACRA Profile" target="_blank">Click here</a></span>to look up your FTN number in your IACRA Profile
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 bg-cover bg-center border-round" style="background-image: url('https://www.faa.gov/sites/faa.gov/files/styles/faa_home_priorities_card/public/2023-02/priorities-equity.jpg?itok=KvASWt28');"></div>
      </div>
    </div>
  </div>

  <!-- <div class="col-10 xl:col-6 lg:col-10 md:col-12 sm:col-12 mt-4 px-6 m-auto">
    <h1>Manage U.S. Agent</h1>
    <div class="border-round-lg p-4 h-auto bg-white"> 
      <div  >
        <div class="grid">
          <div class="col-4">
            <img src="assets/map.png" width="auto" class="w-full" style="object-fit: cover" alt="map-image">
          </div>
          <div class="col-8">
            <h2>Get started by managing your U.S. Agent</h2>
            <p>Click the button below to designate your U.S. Agent for Service</p>
            <button class="primary default add-btn" (click)="addAgent()">Add new U.S. Agent</button>
          </div>
        </div>
      </div>
      <div class="p-2  mb-2" style="line-height: 28px;">
  
        <h2>The FAA uses FTN or Make, Model, Serial Number to uniquely identify each person
          taking actions on many FAA systems. You may already have one of these unique identifiers
          assigned to you. <span class="text-yellow-700">You must know your unique identifier and date of birth to assign
            a U.S. Agent for Service.</span>
        </h2>
        <p>Please enter FTN and DOB to deisgnate/update your U.S. Agent</p>
  
      </div>
      <div class="grid mt-3 mb-3" *ngIf="isError">
        <div style="padding: 14px;">
          <div role="dialog" aria-label="error-message"
            aria-describedby="info-message-body">
            <div class="devkit-message-body-wrapper">
              <p class="devkit-message-body" id="error-message-body">
                  Your FTN or Make, Model, Serial Number is not known to USAS database.
                  If you recently created your FTN, please click submit again to create a new U.S. Agent for Service
                </p>
              <p style="color: #c32026" id="errorMessage"><span><i class="fa-solid fa-triangle-exclamation"
                    style="color: #c32026;"></i></span>
                {{errorMsg}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-items-wrapper" [formGroup]="ftnSearch">
        <div class="form-item-wrapper" style="margin-right: 30px;">
          <label for="ftnNumber">FTN<span class="asterik">*</span></label>
          <input pInputText id="ftnNumber" type="text" maxLength="8" formControlName="ftnNumber"
            style='width: 100%;height: 58%;margin-right: 80px;' required />
        </div>
        <div class="form-item-wrapper">
          <label for="dobEntry" style="display: block;">DOB<span class="asterik">*</span></label>
          <p-calendar placeholder="mm/dd/yyyy" formControlName="dob" [iconDisplay]="'input'" [showIcon]="true" 
          [selectOtherMonths]="true" [readonlyInput]="true"/>
        </div>
      </div>
      <div>
        <button type="submit" class="primary default" (click)="handleFtnSubmit()">Submit</button>
        <button class="secondary default">Cancel</button>
      </div>
      <div class="p-2 bg-cyan-50 mb-2">
        <ul>
          <li>Visit <span><a type="button" class="link-button" href="#devkit-skip-to-main" aria-label="iacra website"
                title="IACRA Website">IACRA.faa.gov</a></span> to either register for a new FTN number or to look up the
            FTN on your account profile. Then return here to complete your application.
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="col-10 xl:col-6 lg:col-10 md:col-10 sm:col-12 mt-4 px-6" *ngIf="showEditMode">
    <h1>Manage U.S. Agent</h1>
    <div class="border-round-lg p-4 h-auto" style="background-color: #8080801a">
      <div class="grid border-bottom-3 border-yellow-600">
        <div class="col-6 xl:col-6 lg:col-6 md:col-6">
          <div class="text-sm font-semibold mb-0">
            Airman / Operator
          </div>
          <div class="text-xl mt-0 capitalize">{{userName}}</div>
        </div>
        <div class="col-6 xl:col-6 lg:col-6 md:col-6 justify-content-end flex-grow-1">
          <a class="edit-link text-sm text-color-primary" (click)="addAgent()" style="float: right;"><i
              class="fa-solid fa-pencil mr-1"></i>Edit or Update</a>
          <br>
          <p class="m-t-0 text-sm" style="float: right;">Last updated: {{agentDetails.lastUpdateDate}}</p>
        </div>
      </div>
      <div class="grid mt-2">
        <div class="col-12">
          <h2 *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-lg font-semibold text-blue-800 mt-0">Individual
            U.S. Agent Details</h2>
          <h2 *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-lg font-semibold text-blue-800 mt-0">Service
            Agent Company Details</h2>

          <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-sm font-semibold mb-0">Individual U.S. Agent
          </div>
          <div *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-sm font-semibold mb-0">Service Agent Company
          </div>
          <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'">{{agentDetails?.firstName}} {{agentDetails?.middleName}}
            {{agentDetails?.lastName}} {{agentDetails?.suffix}}</div>
          <div *ngIf="agentDetails?.isAgentIndividual=== 'N'"> {{agentDetails?.agentCompanyName}}</div>
        </div>
      </div>
      <div class="grid" *ngIf="agentDetails.isAgentMilitaryEmp=== 'Y' && agentDetails?.isAgentIndividual=== 'Y'">
        <div class="col-12">
          <div class="text-sm font-semibold mb-0">Military</div>
          <div>Yes</div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <div class="text-sm font-semibold mb-0">Address</div>
          <div>{{agentDetails?.addressLine1}}</div>
          <div>{{agentDetails?.addressline2}}</div>
          <div>{{agentDetails?.city}}, {{agentDetails?.stateCd}} {{agentDetails?.zipCode}}<span
              *ngIf="agentDetails?.zip4Code">-{{agentDetails?.zip4Code}}</span>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-4 md:col-4 sm:col-12">
          <div class="text-sm font-semibold mb-0">Phone</div>
          <div *ngIf="agentDetails?.phone">{{ agentDetails?.phone}}</div>
        </div>
        <div class="col-6 md:col-6 sm:col-12">
          <div class="text-sm font-semibold mb-0">Fax</div>
          <div>{{agentDetails?.fax}}</div>
        </div>
      </div>
      <div class="grid">
        <div class="col-6 md:col-6 sm:col-12">
          <div class="text-sm font-semibold mb-0">E-mail</div>
          <div>{{agentDetails?.email}}</div>
        </div>
      </div>
      <div *ngIf="agentDetails?.isAgentIndividual=== 'N'">
        <div class="grid border-top-2 mt-2">
          <div class="col-12">
            <h2 class="text-lg font-semibold mb-0 text-blue-800">Service Agent Company Representative</h2>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <div class="text-sm font-semibold mb-0">Name</div>
            <div class="capitalize">{{agentDetails?.firstName}} {{agentDetails?.middleName}} {{agentDetails?.lastName}}
              {{agentDetails?.suffix}}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-4 md:col-4 sm:col-12">
            <div class="text-sm font-semibold mb-0">Phone</div>
            <div *ngIf="agentDetails?.pocPhone">{{ agentDetails?.pocPhone }}</div>
          </div>
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">Fax</div>
            <div>{{agentDetails?.pocFax}}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">E-mail</div>
            <div>{{agentDetails?.pocEmail}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
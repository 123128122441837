import { Component, Input } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsasLoadingService } from '../../shared/components/usas-loading/usas-loading.service';
import { AddInternalUserModalComponent } from '../add-internal-user-modal/add-internal-user-modal.component';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

interface Dataset { 
  username: String, 
  useremail: String, 
  userrole: String,
  userstatus: String,
  lastLoginDate: String,
  updatedon: String,  
}

@Component({
  selector: 'app-internal-user-list',
  templateUrl: './internal-user-list.component.html',
  styleUrl: './internal-user-list.component.scss',
  providers:[DynamicDialogRef, DynamicDialogConfig]
})
export class InternalUserListComponent {
  @Input() searchResults:any
  users: Dataset[] = [];

  showAddUserModal = false;
  addUserform: any;
  isLoading: boolean = false;
  userDetails: any;
  userData:any;
  selectedUser:any;
  first = 0;

  constructor(private dialogService:DialogService, private usasLoadingService: UsasLoadingService,
    public userDialogRef: DynamicDialogRef,private userDetailService: UserDetailsService, 
    public router: Router, private messageService: MessageService,){
      this.usasLoadingService.isLoading.next(false);
   }

  ngOnInit() { 
    this.getInternalUsers({"skip": 0,"take": 100});
  }

  addUser() {
    this.router.navigate(["/add-internal-user"]);
  }

  getInternalUsers(data:any){
    this.userDetailService.getInternalUsers(data).subscribe((results)=>{
      this.userDetailService.setUserSearchResults(results.items);
      this.users= results.items;
    })
  }
  ngOnChanges(changes:any) {
    if(this.searchResults){
      this.first = 0;
      this.users = this.searchResults.items;
    }
  }

  openModal(header?:string){
    this.userDialogRef = this.dialogService.open(AddInternalUserModalComponent, {
      header: header,
      width: 'fit-content',
      contentStyle: {'padding': '25px 25px 25px 25px'},
      height: 'auto'
    });
    this.userDialogRef.onClose.subscribe((formData) => {
      const internalUserForm = formData.formData;
      if(internalUserForm && internalUserForm.status !== "INVALID" && !formData.isCancel){
      this.selectedUser = this.userDetailService.selectedUserData;   
      const data = 
      {
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        email: this.selectedUser.email,
        isActive: internalUserForm.controls['selectedStatus'].value === 'Active' ? 'Y' : 'N',
        userRole: {
          roleId: internalUserForm.controls['selectedRole'].value
        },
      }
    if(this.selectedUser && this.selectedUser?.userRole && this.selectedUser?.userRole?.roleName){
      this.userDetailService.editInternalUsers(data).subscribe((results)=>{
        if(results.message === 'Success'){
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'User has been updated successfully and required privileges /access has been provided',
          });
        }
        if(results.message ==='System shall have at least one active admin at all time'){
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: results.message,
          });
        }
        this.getInternalUsers({"skip": 0,"take": 100});
        formData.event?.preventDefault();
      })
    }
    }   
  })

  }

  editUser(userData?: any){
    this.openModal('Edit User');
    this.userDetailService.selectedUserData = userData;
  }
}

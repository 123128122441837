<div class="grid mt-4">
    <div class="col">
    </div>
    <div class="col flex justify-content-end flex-wrap">
        <button type="submit"  class="primary default" (click)="addUser()">Add New User</button>
    </div>
</div>
<p-toast position="top-center"></p-toast>
<p-table [tableStyle]="{'min-width': '60rem'}" [value]="users" [resizableColumns]="true"
    [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
    styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll" role="table" [(first)]="first">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
           INTERNAL USERS LIST
        </div>
    </ng-template>
    <ng-template pTemplate="header" class="bg-blue-700">
        <tr class="devkit-table-row bg-blue-700">
            <th pSortableColumn="firstName">
                Name <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Last Login Date</th>
            <th>Updated on</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr class="devkit-table-row" role="row">
            <td>{{user.firstName}} {{user.lastName}}</td>
            <td>{{user.email}}</td>
            <td>{{user.userRole.roleName}}</td>
            <td *ngIf="user?.isActive === 'Y'">Active</td>
            <td *ngIf="user?.isActive !== 'Y'">Inactive</td>
            <td>{{user.lastLoginDate}}</td>
            <td>{{user.lastUpdatedDateOn}}</td>
            <td><a rel="noopener noreferrer" class="pl-0 p-button p-button-link" (click)="editUser(user)"><span class="pi pi-user-edit mr-1"></span>Edit</a></td>
        </tr>
    </ng-template>
</p-table>
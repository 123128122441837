import { Injectable, OnDestroy } from '@angular/core';
import { User } from '../models/user-model';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
// import { LoggerService } from './logger/logger.service';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../app-config.module';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserDataService implements OnDestroy {
  userSubscribe: Subscription;
  _currentUser: any;
  _idleTimeoutData: any = null;
  _tokenAvailable: boolean = false;
  _appSettings;
  isInitialLoadVal: boolean = false;

  private userSource = new BehaviorSubject<User>(User);
  currentUser = this.userSource.asObservable();

  private idleTimeoutSource = new BehaviorSubject<any>(null);
  private idleTimeoutData = this.idleTimeoutSource.asObservable();

  private signatureInfoSource = new BehaviorSubject<any>(null);
  private signatureInfo = this.signatureInfoSource.asObservable();
  
  private broswerCloseSource = new BehaviorSubject<any>(null);
  private closeClicked = this.broswerCloseSource.asObservable();

  private tokenAvailableSource = new BehaviorSubject<boolean>(this._tokenAvailable);
  private tokenAvailable = this.tokenAvailableSource.asObservable();

  // private editNotesDurationSource = new BehaviorSubject<any>(null);
  // private editNotesDurationData = this.editNotesDurationSource.asObservable();

  private userUsasPreferencesSource = new BehaviorSubject<any>(null);
  private userUsasPreferences = this.userUsasPreferencesSource.asObservable();

  private hasUsasAccess = new BehaviorSubject<any>(null);
  // hasUsasAccess: any;

  constructor(private httpClient: HttpClient) {
    // Getting current user update
    this.userSubscribe = this.getCurrentUser().subscribe(user => {
      this._currentUser = user;
    });
    this._appSettings = AppSettings;
  }

  setUser(user: User) {
    this.userSource.next(user);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  setInitialLoad() {
    this.isInitialLoadVal = true;
  }

  isInitialLoad() {
    return this.isInitialLoadVal;
  }

  setIdleTimeoutInfo(data: any) {
    this.idleTimeoutSource.next(data);
  }

  getIdleTimeoutInfo() {
    return this.idleTimeoutData;
  }

  setSignatureDetails(data: any) {
    this.signatureInfoSource.next(data);
  }

  getSignatureDetails() {
    return this.signatureInfo;
  }

  // tslint:disable-next-line:typedef
  setBrowserClose(data: any) {
    this.broswerCloseSource.next(data);
  }

  // tslint:disable-next-line:typedef
  getBroswerClose() {
    return this.closeClicked;
  }

  // setEditNotesDuration(data: any) {
  //   this.editNotesDurationSource.next(data);
  // }

  // getEditNotesDurationInfo() {
  //   return this.editNotesDurationData;
  // }

  setTokenAvailability(token: boolean) {
    this.tokenAvailableSource.next(token);
  }

  getTokenAvailability() {
    return this.tokenAvailable;
  }

  setUserUsasPreferences(preferences: any) {
    this.userUsasPreferencesSource.next(preferences);
  }

  getUserUsasPreferences() {
    return this.userUsasPreferences;
  }

  setUsasAccess(canAccess: any){
    this.hasUsasAccess.next(canAccess);
  }

  getUsasAccess(){
    return this.hasUsasAccess.asObservable();
  }

  /* Authenticate */
  isAuthorizedUser(perms: any): boolean {
    let isAuthorized = false;
    if (this._currentUser && this._currentUser['permissions']) {
      const permissions = this._currentUser['permissions'];
      if (typeof perms === 'string') {
        if (permissions.indexOf(perms) > -1) {
          isAuthorized = true;
          return isAuthorized;
        }
      } else if (Array.isArray(perms)) {
        perms?.forEach(perm => {
          if (permissions.indexOf(perm) > -1) {
            isAuthorized = true;
            return isAuthorized;
          }
        });
      }
    }
    return isAuthorized;
  }

  /**
   * Unsubscribe on destory
   */
  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
  }


  updateUserUsasPreferences(obj: any) {
    return this.httpClient.post<any>(`${this._appSettings.API_ENDPOINT}User/UpdateUserPreferences`, obj)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  getUserPreferences() {
    return this.httpClient.get<any>(`${this._appSettings.API_ENDPOINT}User/GetUserPreferences`)
      .pipe(catchError(err => this.errorHandler(err)));
  }

  getAvailableSignatures() {
    return this.httpClient.get<any>(`${this._appSettings.API_ENDPOINT}User/GetAvailableSignatures`)
      .pipe(catchError(err => this.errorHandler(err)));
  }

  errorHandler(error: any): any {
    if (error.status === 401) {
      window.location.href = 'signout';
    }
    return throwError(new Error(error.error.error));
  }

}

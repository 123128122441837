<div class="grid mt-4">
    <div class="col">
        <h2>Search Results</h2>
    </div>
    <div class="col flex justify-content-end flex-wrap">
        <button type="submit"  class="primary default" (click)="downloadSearchReport()">Download search results</button>
    </div>
</div>
<p-table #table [tableStyle]="{'min-width': '60rem'}" [value]="users" [resizableColumns]="true"
    [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [totalRecords]="searchResults.totalCount" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
    styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll" role="table" [(first)]="first" (onSort)="onSort($event)">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
           U.S. AGENTS LIST
        </div>
    </ng-template>
    <ng-template pTemplate="header" class="bg-blue-700">
        <tr class="devkit-table-row bg-blue-700">
            <th id="hAirmen" pSortableColumn="operatorFirstName" style="width: auto">Airman / Operator 
                <p-sortIcon field="operatorFirstName"></p-sortIcon>
            </th>
            <th id="hAirmenEmail">Airman / Operator Email</th>
            <th id="hAgentType">Agent Type</th>
            <th id="hMilitary">Military</th>
            <th id="hCompanyName">Company Name</th>
            <th id="hAgentName">Agent Name</th>
            <th id="hAgentAddress">Agent Address</th>
            <th id="hAgentPhone">Agent Phone</th>
            <th id="hAgentFax">Agent Fax Number</th>
            <th id="hAgentEmail">Agent Email</th>
            <th id="hRepName">Representative Name</th>
            <th id="hRepPhone">Representative Phone</th>
            <th id="hRepFax">Representative Fax Number</th>
            <th id="hRepEmail">Representative Email</th>
            <th id="hAirmenEmail">Last Updated On</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr class="devkit-table-row" role="row">
            <td>{{user?.operatorFirstName}} {{user?.operatorLastName}}</td>
            <td>{{user?.operatorEmail}}</td>
            <td *ngIf="user.isAgentIndividual === 'Y'">Individual</td>
            <td *ngIf="user.isAgentIndividual === 'N'">Service Agent Company</td>
            <td>{{user?.isAgentMilitaryEmp}}</td>     
            <td *ngIf="user.isAgentIndividual === 'N'">{{user?.agentCompanyName}}</td>
            <td *ngIf="user.isAgentIndividual === 'Y'">N/A</td>
            <td *ngIf="user.isAgentIndividual === 'Y'">{{user?.firstName}} {{user?.middleName}} {{user?.lastName}} {{user?.suffix}}</td>
            <td *ngIf="user.isAgentIndividual === 'N'">N/A</td>
            <td *ngIf="user?.addressline2">{{user?.addressLine1}}, {{user?.addressline2}}, {{user?.city}}, {{user?.stateCd}} {{user?.zipCode}}</td>
            <td *ngIf="!user?.addressline2">{{user?.addressLine1}}, {{user?.city}}, {{user?.stateCd}} {{user?.zipCode}}</td>
            <td>{{user?.phone}}</td>
            <td>{{user?.fax}}</td>
            <td>{{user?.email}}</td>
            <td *ngIf="user.isAgentIndividual === 'Y'"></td>
            <td *ngIf="user.isAgentIndividual === 'N'">{{user?.firstName}} {{user?.middleName}} {{user?.lastName}} {{user?.suffix}}</td>
            <td>{{user?.pocPhone}}</td>            
            <td>{{user?.pocFax}}</td>            
            <td>{{user?.pocEmail}}</td>
            <td>{{user?.lastUpdateDateEst}}</td>
        </tr>
    </ng-template>
</p-table>

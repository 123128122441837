import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CustomUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  safeDecodeURIComponent(value: string): string {
    try {
      let sanitizedValue = value;
      if(value.indexOf('%%%') >= 1){
       sanitizedValue = value.replace(/%%%/g, '%25');
      }else if(value.indexOf('%%') >= 1){
        sanitizedValue = value.replace(/%%/g, '%25');
      }
      //return decodeURIComponent(sanitizedValue);
      return sanitizedValue;
    } catch (error) {
      console.warn('Malformed URI', value, error);
      return value;
    }
  }

   parse(url: string): UrlTree {
    try
    {
      const decodeUrl = this.safeDecodeURIComponent(url);
      return this.defaultUrlSerializer.parse(decodeUrl);
    } 
    catch (error) 
    {
      console.log('Error Occurred', error);
      throw error;
    }    
  }   

  serialize(tree: UrlTree): string {
    return this.defaultUrlSerializer.serialize(tree);
  }
}